import React, {useState, useEffect} from "react";
import "./memory.css";
import {Link} from "react-router-dom";

function MemoryGame() {
    const [cards, setCards] = useState([]);
    const [flippedCards, setFlippedCards] = useState([]);
    const [matchedCards, setMatchedCards] = useState([]);
    const [score, setScore] = useState(0);
    const [showRules, setShowRules] = useState(true);
    const [lost, setLost] = useState(false);
    const [win, setWin] = useState(false);
    const [finish, setFinish] = useState(false);

    const initializeCards = () => {
        const symbols = ["🍎", "🍊", "🍉", "🍌", "🍓", "🥝", "🍇", "🍒"];
        const initialCards = symbols
            .concat(symbols)
            .sort(() => Math.random() - 0.5);
        setCards(initialCards);
    };

    useEffect(() => {
        initializeCards();
    }, []);

    const handleCardClick = (index) => {
        if (flippedCards.length === 2 || matchedCards.includes(index)) return;

        setFlippedCards((prevFlippedCards) => [...prevFlippedCards, index]);

        if (flippedCards.length === 1) {
            if (cards[index] === cards[flippedCards[0]]) {
                setScore(score + 1);
                setMatchedCards((prevMatchedCards) => [
                    ...prevMatchedCards,
                    index,
                    flippedCards[0],
                ]);
                if (matchedCards.length + 2 === cards.length) {
                    setWin(true);
                }
            }
            setTimeout(() => {
                setFlippedCards([]);
            }, 1000);
        }
    };

    const rules = () => {
        const rulesTxt = `Tentez de trouver toutes les paires de cartes en retournant deux cartes à la fois. Chaque fois que vous retournez deux cartes, elles resteront visibles pendant une seconde, puis se retourneront à nouveau. Vous gagnez le jeu lorsque toutes les paires sont trouvées. Bonne chance !`;
        return (
            <div className="gameContainer">
                <div className="content">
                    <h2 className="content__title">Memory Game</h2>
                    <p className="content__rules">{rulesTxt}</p>
                    <span className="content__info">
                        🛈 Les jeux changent tous les jours à 8h00.
                    </span>
                    <button
                        className="content__button main__btn"
                        onClick={togglePlay}
                    >
                        Lancer le jeu →
                    </button>
                </div>
                <div className="ranking__ingame">
                    <p className="ranking__txt">
                        Votre classement national : 72/148
                    </p>
                    <p className="ranking__txt">
                        Votre classement départemental : 9/21
                    </p>
                </div>
            </div>
        );
    };

    const losingModal = () => {
        return (
            <div className="losingModal">
                <div className="content">
                    <h2 className="content__title">Vous avez perdu...</h2>
                    <img
                        src="/assets/images/losing_memory.svg"
                        alt="Illustration de défaite pour le jeu de Memory"
                        className="losing__img"
                    />
                    <p>
                        Oups, il semble que vous n'ayez pas trouvé toutes les
                        paires...
                    </p>
                    <Link className="second__btn" to={"/gamesPage"}>
                        Retourner aux jeux →
                    </Link>
                </div>
            </div>
        );
    };

    const returnModal = () => {
        return (
            <div className="returnModal">
                <div className="content">
                    <h2 className="content__title">Vous avez gagné !</h2>
                    <img
                        src="/assets/images/logo.png"
                        alt="logo flamme avec un visage heureux"
                        className="winning__img"
                    />
                    <p>🎉 Vous avez gagné x5 jetons 🎉</p>
                    <Link className="second__btn" to={"/games"}>
                        Retourner aux jeux →
                    </Link>
                </div>
            </div>
        );
    };

    const winningModal = () => {
        return (
            <div className="modal">
                <h2>Bravo, vous avez gagné !</h2>
                <p>Vous avez réussi à trouver toutes les paires.</p>
                {finish && returnModal()}
            </div>
        );
    };

    const resetGame = () => {
        setFlippedCards([]);
        setMatchedCards([]);
        setScore(0);
        initializeCards();
    };

    const togglePlay = () => {
        setShowRules((prevShowRules) => !prevShowRules);
    };

    return (
        <div className="memory-game">
            {showRules && rules()}

            {lost && losingModal()}

            {win && returnModal()}

            {!showRules && (
                <>
                    <div>Score: {score}</div>
                    <div className="cards">
                        {cards.map((symbol, index) => (
                            <div
                                key={index}
                                className={`card ${
                                    flippedCards.includes(index) ||
                                    matchedCards.includes(index)
                                        ? "flipped"
                                        : ""
                                }`}
                                onClick={() => handleCardClick(index)}
                            >
                                <span className="symbol">
                                    {flippedCards.includes(index) ||
                                    matchedCards.includes(index)
                                        ? symbol
                                        : "?"}
                                </span>
                            </div>
                        ))}
                    </div>
                </>
            )}

            <button onClick={togglePlay}>
                {showRules ? "" : "Retour aux règles"}
            </button>
        </div>
    );
}

export default MemoryGame;

import "./bearer.css";
import {useState} from "react";

const Bearer = ({newBearer}) => {
    const [closed, setClosed] = useState(false);
    let classImg = "bearerImg";
    if (newBearer) classImg = "bearerImg__new";
    // const [pass, setPass] = useState(false);
    // const [newBearer, setNewBearer] = useState(false);

    if (!closed) {
        return (
            <>
                <span
                    onClick={() => {
                        setClosed(true);
                    }}
                    dir="rtl"
                    aria-label="Fermer l'image"
                    className="BearerImg__close"
                >
                    ✖
                </span>
                <div className={classImg}></div>
            </>
        );
    } else return null;
};

export default Bearer;

import {useState, useEffect, useCallback} from "react";
import "./countDown.css";
import Bearer from "../Bearer/Bearer";
import ApiHandler from "../../services/ApiHandler";
import useAuthContext from "../../hooks/useAuthContext";

const CountDown = ({timing}) => {
    // const bearer_timestamp = "2024-03-19T10:38:37.000Z";
    const {userData} = useAuthContext();
    let parsedTimestamp;
    let user;

    if (userData) {
        parsedTimestamp = new Date(userData.user.bearer_timestamp);
        user = userData.user;
    }

    let currentTimeStamp = new Date();
    // console.log("current time" + currentTimeStamp);
    currentTimeStamp = parsedTimestamp - currentTimeStamp;
    // console.log("diff time" + currentTimeStamp);
    // console.log("bearer time" + parsedTimestamp);
    const endTimestamp = new Date(
        parsedTimestamp.getTime() + 24 * 60 * 60 * 1000
    );

    const [hours, setHours] = useState(new Date(timing).getHours());
    const [minutes, setMinutes] = useState(new Date(timing).getMinutes());
    const [seconds, setSeconds] = useState(new Date(timing).getSeconds());
    const [check, setCheck] = useState(false);
    //if newBearer, get interfaces and set bearer: false
    const [newBearer, setNewBearer] = useState(false);
    //if bearer, get the "pass flame" button

    //call api in auth context
    const [bearer, setBearer] = useState(false);

    const nextBearer = useCallback(
        (hours) => {
            //call API to change bearer and bequeath the flame to the first user ranked
            // ApiHandler('nextBearer', 'POST', timeDiff);

            //86400000 = 24h
            // const timeDiff = endTimestamp - parsedTimestamp;
            //if too long to give it : get small flame, else get bigger
            ApiHandler("nextBearer", "POST");

            if (hours <= 12) {
                ApiHandler("flameSize/small", "POST");
            }
            if (hours >= 12) {
                // Do something
                ApiHandler("flameSize/middle", "POST");
            }
            if (hours <= 22) {
                ApiHandler("flameSize/huge", "POST");
            }
        },
        [hours]
    );

    useEffect(() => {
        //timer logic
        const interval = setInterval(() => {
            setSeconds((prevSeconds) => {
                if (prevSeconds === 0) {
                    setMinutes((prevMinutes) => {
                        if (prevMinutes === 0) {
                            setHours((prevHours) => {
                                if (prevHours === 0 || prevHours <= 0) {
                                    //end timer when hours = 0
                                    clearInterval(interval);
                                    nextBearer(hours);
                                    //call API to get the new user
                                    return 0;
                                } else {
                                    return prevHours - 1;
                                }
                            });

                            //reset timer minutes
                            return 59;
                        } else {
                            return prevMinutes - 1;
                        }
                    });

                    //reset timer seconds
                    return 59;
                } else {
                    return prevSeconds - 1;
                }
            });
        }, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }, [hours, minutes, seconds, nextBearer]);

    const checkModal = () => {
        return (
            <div className="deleteModal__container">
                <div className="content deleteModal">
                    <span
                        onClick={() => {
                            setCheck(false);
                        }}
                        dir="rtl"
                    >
                        ✖
                    </span>
                    <p>Voulez-vous vraiment passer la flamme ? </p>
                    <button
                        className="main__btn delete__btn"
                        onClick={() => {
                            setNewBearer(true);
                            //temp
                            //API call to change the bearer
                            setBearer(false);
                            setCheck(false);
                        }}
                    >
                        Oui, passer la flamme
                    </button>
                </div>
            </div>
        );
    };

    return (
        <>
            {newBearer && <Bearer newBearer="" />}
            {check && checkModal()}
            <div className="timing">
                <img
                    className="timing__img"
                    src={`/assets/avatars/${user.team}/${user.avatar}.png`}
                    alt=""
                />
                <p>@{user.username}</p>
                <span className="countdown">
                    {hours + "h : " + minutes + "m : " + seconds + "s "}
                </span>
            </div>
            {bearer && (
                <div className="bearer">
                    <button
                        className="main__btn"
                        onClick={() => {
                            setCheck(true);
                        }}
                    >
                        Passer la flamme
                    </button>
                </div>
            )}
        </>
    );
};

export default CountDown;

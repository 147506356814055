import "./flameHome.css";
import {useState, useEffect, useRef} from "react";
import Menu from "../menu/Menu";
import CountDown from "../countDown/CountDown";
import RankDpt from "../rank/RankDpt";
import RankTeam from "../rank/RankTeam";
import Bearer from "../Bearer/Bearer";
import useAuthContext from "../../hooks/useAuthContext";

const FlameHome = () => {
    const [progress, setProgress] = useState(null /* || user.timeSpend() */);
    const [rankScale, setRankScale] = useState(1);
    const [timing, setTiming] = useState(null);
    const chartRef = useRef();
    const [isBearer, setIsBearer] = useState(null);
    const {userData, flameSize} = useAuthContext();
    const [flameUrl, setFlameUrl] = useState("/assets/flamme/feu_middle.gif");
    const [flameAlt, setFlameAlt] = useState(
        "flamme du département de taille moyenne"
    );

    useEffect(() => {
        if (userData) {
            if (userData.activeFlameDepartement) {
                setFlameUrl("/assets/flamme/feu_dore.gif");
            }
            switch (flameSize) {
                case "small":
                    setFlameUrl("/assets/flamme/feu_small.gif");
                    setFlameAlt("flamme du département de petite taille");
                    break;
                case "middle":
                    setFlameUrl("/assets/flamme/feu_middle.gif");
                    setFlameAlt("flamme du département de taille moyenne");
                    break;
                case "huge":
                    setFlameUrl("/assets/flamme/feu_huge.gif");
                    setFlameAlt("flamme du département de grande taille");
                    break;

                default:
                    break;
            }
        }
        //setIsBearer(user.bearer); call api in auth context
        setIsBearer(false);

        const interval = setInterval(() => {
            // const bearer_timestamp = "2024-03-19T10:38:37.000Z";
            // const parsedTimestamp = new Date(bearer_timestamp);

            // let updateDate = parsedTimestamp;
            if (userData && userData.user) {
                setTiming(userData.user.bearer_timestamp);
            }
            // console.log(timing);

            //*progress : current timing for chart value ( in deg, between 0 to 180 )
            if (timing) {
                const hours = new Date(timing).getHours();
                const minutes = new Date(timing).getMinutes();
                const seconds = new Date(timing).getSeconds();
                const totalSeconds = seconds + minutes * 60 + hours * 3600;
                const progressAngle =
                    ((24 * 3600 - totalSeconds) / (24 * 3600)) * 180; // Calculate the angle based on the remaining time
                const reversedProgressAngle = 180 - progressAngle; // Reverse the progress angle

                setProgress(reversedProgressAngle);

                chartRef.current.setAttribute(
                    "style",
                    `transform: rotate(${reversedProgressAngle}deg)`
                );
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [progress, timing, chartRef]);

    //call API current flame holder to get the timing remaining and show the progress
    //  > chart showing time left to transfer it

    return (
        <>
            {isBearer && <Bearer newBearer="true" />}
            <div className="flameHome container">
                <h1 className="second__title">Relais de la flamme</h1>
                <div className="chart">
                    <span className="chart__hidden"></span>
                    <span ref={chartRef} className="chart__time"></span>
                </div>

                {/* src depends on flame size, get with call API */}
                <img
                    className="flameImg"
                    // src="/assets/flamme/Feu_Huge2.gif"
                    src={flameUrl}
                    alt={flameAlt}
                    height="240"
                />
                {userData && userData.activeFlameDepartement && (
                    <p className="olympic__mssg">
                        Vous concourez pour la flamme olympique !
                    </p>
                )}
                {timing && <CountDown timing={timing}></CountDown>}
                <div className="ranking">
                    <div className="rankingNav">
                        <span
                            onClick={(e) => {
                                setRankScale(1);
                            }}
                        >
                            Départemental
                        </span>
                        <span
                            onClick={(e) => {
                                setRankScale(2);
                            }}
                        >
                            National
                        </span>
                        <span
                            onClick={(e) => {
                                setRankScale(0);
                            }}
                        >
                            Équipe
                        </span>
                    </div>
                    {rankScale === 0 ? (
                        <RankTeam />
                    ) : (
                        <RankDpt scale={rankScale} />
                    )}
                </div>
            </div>
            <Menu currentPage="flame" />
        </>
    );
};

export default FlameHome;

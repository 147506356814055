import "./rank.css";
import useAuthContext from "../../hooks/useAuthContext";
import {useState} from "react";

const RankTeam = () => {
    const {userData} = useAuthContext();
    const [classNameColors, setClassNameColors] = useState(`teamItem__color`);

    const getClassNameColors = (team) => {
        switch (team) {
            case "Force":
                return "teamItem__color teamRank__blue";
            case "Vitesse":
                return "teamItem__color teamRank__white";
            case "Précision":
                return "teamItem__color teamRank__red";
            default:
                return "teamItem__color";
        }
    };

    const teamItem = () => {
        if (userData && userData.topPointDepartment) {
            return userData.topTeams.map((unit) => {
                return (
                    <li key={unit.team} className="teamRankItem">
                        <div className="teamRankItem__header">
                            <h3 className="">Équipe {unit.team}</h3>
                            <span
                                className={getClassNameColors(unit.team)}
                            ></span>
                        </div>
                        <div className="teamRankItem__details">
                            <span>{unit.total_score} points</span>
                            <span>{unit.total_medails} médailles</span>
                            <span>{unit.total_players} joueurs </span>
                        </div>
                    </li>
                );
            });
        } else {
            return (
                <p>
                    Les données n'ont pas pu s'afficher, veuillez réessayer plus
                    tard.{" "}
                </p>
            );
        }
    };

    return (
        <div className="ranking__content">
            <>
                <h2 className="ranking__title">Statistiques équipes</h2>
                <ul className="rank">{teamItem()}</ul>
            </>
        </div>
    );
};

export default RankTeam;

import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import "./registerValid.css";

const RegisterValid = () => {
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    const newData = [
        {
            index: "0",
            url: `/assets/images/logo.png`,
            alt: `Logo burnner`,
            txt: `Bienvenue sur Burnner, le relais de la flamme pour tous ! Jouez aux mini jeux pour gagner des points et entrer dans le classement des 5 meilleurs dans votre département. Entrez dans le top 3 et collectionnez les médailles pour entrer dans la compétitionà l'échelle nationale !`,
        },
        {
            index: "1",
            url: `/assets/images/welcome-team.png`,
            alt: `Départements avec chacunn leur flamme`,
            txt: `Portez la flamme et léguez la un autre joueur présent dans le classement en moins de 24 heures. Plus vous la passez vite, plus la flamme de votre département sera grande ! Attention, si vous tardez elle risque de rétrécir... Obtenez la flamme au moment où la flamme réelle passe dans votre département pour avoir l'occasion d'être le porteur mis en avant sur la carte.`,
        },
        {
            index: "2",
            url: `/assets/images/welcome-avatar.png`,
            alt: `Avatar paralympique brandissant la torche`,
            txt: `Jouez quand vous désirez sans vous prendre la tête; afin d'éviter de trop gros écarts entre joueurs, les points des joueurs sont remis à zéro tous les 4 jours, laissant l'opportunité à chacun d'avoir une période plus productive pour entrer dans le classement.`,
        },
    ];

    useState(() => {
        setData(newData[0]);
    }, []);

    function nextHandle(i) {
        const index = parseInt(i);
        setData(newData[index + 1]);
    }

    return (
        <div className="registerValid__container container">
            {data && (
                <div className="registerValid__main">
                    <div className="registerValid__header">
                        <img src={data.url} alt={data.alt} />
                        <h1 className="registerValid__title second__title">
                            Burnner tutoriel
                        </h1>
                    </div>
                    <div>
                        <p>{data.txt}</p>
                    </div>
                    {parseInt(data.index) !== newData.length - 1 ? (
                        <span
                            className="second__btn registerValid__btn"
                            onClick={() => nextHandle(data.index)}
                        >
                            Continuer →
                        </span>
                    ) : (
                        <span
                            className="second__btn registerValid__btn"
                            onClick={() => navigate("/")}
                        >
                            Burn, run and get the torch ! →
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

export default RegisterValid;

const ApiHandler = async (endpoint, method, dataToFetch, contentType = 'multipart/form-data', navigate) => {
    const fetchData = async () => {
        const options = {
            method: method,
            credentials: 'include',
        };
        if (dataToFetch) options.body = dataToFetch;
        try {
            const response = await fetch(`http://alexlatremoliere.fr:3000/${endpoint}`, options);
            // console.log(response);
            if (response.redirected) {
                console.log(dataToFetch);
                if (navigate) {
                    console.log('go to login');
                    window.location.href = '/';
                }
                return;
            }
            if (response.ok) {
                console.log('response OK');
                console.log(endpoint);
                if (navigate && endpoint === 'register') {
                    console.log('go to welcome');
                    console.log(response);
                    window.location.href = '/welcome';
                }
            }
            console.log(endpoint);
            return await response.json();
        } catch (error) {
            console.log('Error getting info:', error);
            return error;
            // throw new Error(error);
        }
    };

    return await fetchData();
};

export default ApiHandler;

import "./login.css";
import React, {useState, useEffect} from "react";
import ApiHandler from "../../services/ApiHandler.jsx";
import {useNavigate} from "react-router";
import {Link, redirect} from "react-router-dom";
import PwaInstall from "../pwaInstall/PwaInstall.jsx";

const Login = () => {
    const [forgottenPw, setForgottenPw] = useState(false);
    const [formData, setFormData] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const navigate = useNavigate();
    const [showGif, setShowGif] = useState(true);
    const [showLogo, setShowLogo] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowGif(false);
        }, 11000);

        return () => clearTimeout(timeout);
    }, []);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowLogo(false);
        }, 13000);

        return () => clearTimeout(timeout);
    }, []);

    const forgotPw = () => {
        setForgottenPw((prevState) => !prevState);
    };

    const handleFormSubmit = async (ev) => {
        ev.preventDefault();
        const form = ev.target;
        setFormData(new FormData(form));
        const res = await ApiHandler("login", "POST", formData, navigate);
        console.log(res);
        if (res.success === true) {
            window.location.href = "/home";
        } else if (res.error === "not allow") {
            setErrorMsg(
                "La connexion a �chou�e, veuillez v�rifiez vos donn�es."
            );
        }
    };

    const newPwForm = () => (
        <>
            <h2 className="login__title">Mot de passe oubli�</h2>
            <form className="login__form" onSubmit={handleFormSubmit}>
                <span onClick={forgotPw} className="login__link">
                    ? Retour
                </span>
                <div className="login__formItem">
                    <label className="login__formLabel" htmlFor="name">
                        Votre identifiant :
                    </label>
                    <input
                        required
                        className="login__formInput"
                        name="name"
                        placeholder="username"
                        type="text"
                    />
                </div>
                <div className="login__formItem">
                    <label className="login__formLabel" htmlFor="mail">
                        Votre adresse mail :
                    </label>
                    <input
                        required
                        className="login__formInput"
                        name="mail"
                        placeholder="marcel.pagnol@etu.univ-poitiers.fr"
                        type="email"
                    />
                </div>
                <button className="login__submit" type="submit">
                    Demander un nouveau mot de passe ?
                </button>
            </form>
        </>
    );

    return (
        <main className="login__container container">
            <header className="header">
                {showLogo ? (
                    <img
                        className="logoAnim"
                        src="/assets/flamme/feu-logo.gif"
                        alt="animation logo flamme"
                    />
                ) : (
                    <img
                        className="logo"
                        src="/assets/images/logo.png"
                        alt="logo flamme avec un visage"
                    />
                )}
                {showGif ? (
                    <img
                        src="/assets/flamme/burnner.gif"
                        alt=""
                        className="burnnerAnim"
                    />
                ) : (
                    <h1 className="title burnner__title">Burnner</h1>
                )}
            </header>
            <div className="login">
                {forgottenPw ? (
                    newPwForm()
                ) : (
                    <>
                        <h2 className="login__title">Connexion</h2>
                        <form
                            id="form"
                            className="login__form"
                            onSubmit={handleFormSubmit}
                        >
                            <div className="login__formItem">
                                <label
                                    className="login__formLabel"
                                    htmlFor="name"
                                >
                                    Votre identifiant :
                                </label>
                                <input
                                    className="login__formInput"
                                    name="name"
                                    placeholder="username"
                                    type="text"
                                />
                            </div>
                            <div className="login__formItem">
                                <label
                                    className="login__formLabel"
                                    htmlFor="password"
                                >
                                    Votre mot de passe :
                                </label>
                                <input
                                    className="login__formInput"
                                    name="password"
                                    placeholder="***********"
                                    type="password"
                                />
                                <span
                                    onClick={forgotPw}
                                    className="login__link"
                                >
                                    <Link to="/register">
                                        Pas de compte ? Inscrivez-vous ?
                                    </Link>
                                    <br />
                                    {errorMsg && (
                                        <span className="error__msg">
                                            {errorMsg}
                                        </span>
                                    )}
                                </span>
                            </div>
                            <button className="login__submit" type="submit">
                                Se connecter ?
                            </button>
                        </form>
                        <PwaInstall />
                    </>
                )}
            </div>
        </main>
    );
};

export default Login;
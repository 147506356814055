import React, {useState} from "react";
import ScratchCard from "react-scratchcard";
import scratchBg from "../../assets/photos/scratchcard.png";

const ScratchCardComponent = ({image}) => {
    console.log(image);
    const imageUrl = "../../assets/photos/" + image + ".jpg";
    const [isScratchingEnabled, setIsScratchingEnabled] = useState(true);
    const [isCompleted, setIsCompleted] = useState(false);
    const [progress, setProgress] = useState(0);

    const handleScratch = () => {
        const newProgress = progress + 1;
        setProgress(newProgress);

        if (newProgress >= 15) {
            setIsScratchingEnabled(false);
            setIsCompleted(true);
        }
    };

    const settings = {
        width: 225,
        height: 225,
        image: scratchBg,
        finishPercent: 100,
        onComplete: () => {},
        onScratch: handleScratch,
    };

    return (
        <div style={{position: "relative"}}>
            <ScratchCard
                {...settings}
                disabled={!isScratchingEnabled}
                className="scratchCard__canvas"
            >
                <img src={imageUrl} alt={image} className="scratchCard__img" />
            </ScratchCard>
        </div>
    );
};

export default ScratchCardComponent;

import "./menu.css";
import {Link} from "react-router-dom";

const Menu = ({currentPage}) => {
    let options = [
        {
            name: `flame`,
            img: (
                <svg
                    width="26"
                    height="30"
                    viewBox="0 0 26 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12.9094 16.9643C6.68363 15.4097 1.91672 21.3182 5.57424 27.1234C-0.893914 20.6627 0.559071 14.7874 3.46503 10.4335C6.37097 6.07955 14.3624 2.4513 23.0803 1C21.6273 2.45123 21.2641 3.90246 20.9009 5.35369L20.9008 5.3539C20.5376 6.8052 20.6587 7.77274 20.9008 8.98215L23.547 5.3539C24.2735 7.28897 25 11.1591 25 16.9643C25 24.2208 21.5571 30.7517 11.3862 28.5747C19.4478 28.5747 19.1352 18.519 12.9094 16.9643Z"
                        stroke="url(#paint0_linear_129_799)"
                        strokeWidth="2"
                        strokeLinejoin="round"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_129_799"
                            x1="22.5"
                            y1="1"
                            x2="4"
                            y2="35.5"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FF4E50" />
                            <stop offset="1" stopColor="#F9D423" />
                        </linearGradient>
                    </defs>
                </svg>
            ),
        },
        {
            name: `map`,
            img: (
                <svg
                    width="27"
                    height="30"
                    viewBox="0 0 27 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.33337 12C1.33337 17.5152 7.0725 24 13.5789 29C19.0725 24 25.3334 17.5 25.3334 12C25.3334 6.5 22.1722 1 13.5789 1C4.98555 1 1.33337 6.48485 1.33337 12Z"
                        stroke="url(#paint0_linear_129_801)"
                        strokeWidth="2"
                        strokeLinejoin="round"
                    />
                    <circle
                        cx="13.3334"
                        cy="11.5"
                        r="6.5"
                        stroke="url(#paint1_linear_129_801)"
                        strokeWidth="2"
                        strokeLinejoin="round"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_129_801"
                            x1="22.8334"
                            y1="1"
                            x2="4.33338"
                            y2="35.5"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FF4E50" />
                            <stop offset="1" stopColor="#F9D423" />
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_129_801"
                            x1="18.4792"
                            y1="5"
                            x2="10.6533"
                            y2="22.0267"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FF4E50" />
                            <stop offset="1" stopColor="#F9D423" />
                        </linearGradient>
                    </defs>
                </svg>
            ),
        },
        {
            name: `games`,
            img: (
                <svg
                    width="38"
                    height="22"
                    viewBox="0 0 38 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6.82458 9H13.8246"
                        stroke="#FD863D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10.5589 5.71927V8.9233V12.1273M18.1434 1.55293C23.5129 1.55293 26.9239 0.78988 29.5634 1.2827C32.2029 1.77552 39.0844 18.7228 34.7888 20.6851C30.4932 22.6473 25.1237 14.7983 19.2173 14.7983C18.7083 14.7983 17.8053 14.7983 17.8053 14.7983C12.0372 14.7983 7.91946 21.9434 3.36219 20.6851C-2.63872 19.0282 5.27995 2.05058 7.91946 1.28293C10.559 0.515289 12.8645 1.55293 18.1434 1.55293Z"
                        stroke="url(#paint0_linear_129_805)"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <circle cx="25.8246" cy="7" r="1" fill="#FE6249" />
                    <circle cx="23.8246" cy="9" r="1" fill="#FE6249" />
                    <circle cx="27.8246" cy="9" r="1" fill="#FE6249" />
                    <circle cx="25.8246" cy="11" r="1" fill="#FE6249" />
                    <defs>
                        <linearGradient
                            id="paint0_linear_129_805"
                            x1="32.5208"
                            y1="1"
                            x2="24.7252"
                            y2="30.6813"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FF4E50" />
                            <stop offset="1" stopColor="#F9D423" />
                        </linearGradient>
                    </defs>
                </svg>
            ),
        },
        {
            name: `profile`,
            img: (
                <svg
                    width="28"
                    height="30"
                    viewBox="0 0 28 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <ellipse
                        cx="13.8406"
                        cy="7.90909"
                        rx="6.90909"
                        ry="6.90909"
                        stroke="url(#paint0_linear_129_813)"
                        strokeWidth="2"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M26.2042 29.0002H14.2042H1.84058C0.0223993 29.0002 2.93149 23.182 4.0224 21.3638C5.11331 19.5456 8.74967 16.6365 14.2042 16.6365C19.2951 16.6365 22.5678 19.5456 23.6587 21.3638C24.7496 23.182 28.0224 29.0002 26.2042 29.0002Z"
                        stroke="url(#paint1_linear_129_813)"
                        strokeWidth="2"
                        strokeLinejoin="round"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_129_813"
                            x1="19.3103"
                            y1="1"
                            x2="10.9918"
                            y2="19.0983"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FF4E50" />
                            <stop offset="1" stopColor="#F9D423" />
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_129_813"
                            x1="24.0886"
                            y1="16.6365"
                            x2="19.9225"
                            y2="35.3218"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FF4E50" />
                            <stop offset="1" stopColor="#F9D423" />
                        </linearGradient>
                    </defs>
                </svg>
            ),
        },
    ];

    const menuItem = () => {
        return options.map((elm) => {
            return (
                <Link key={elm.name} to={"/" + elm.name}>
                    <li
                        className={
                            currentPage === elm.name
                                ? "menuList__item selected"
                                : "menuList__item"
                        }
                    >
                        {elm.img}
                    </li>
                </Link>
            );
        });
    };

    return (
        <div className="menu">
            <ul className="menuList">{menuItem()}</ul>
        </div>
    );
};

export default Menu;

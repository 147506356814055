import './profile.css';
import Menu from '../menu/Menu';
import { useState, useEffect } from 'react';
import ApiHandler from '../../services/ApiHandler';
import { useNavigate } from 'react-router-dom';
import InfosCard from '../infosCard/InfosCard';
import useAuthContext from '../../hooks/useAuthContext';

const Profile = () => {
    const navigate = useNavigate();
    // Call API to get user info
    //temp
    const { userData } = useAuthContext();
    console.log(userData);
    const { username: pseudo, lastname, firstname, mail } = userData.user;
    const user = { pseudo, lastname, firstname, mail };

    const [askDelete, setAskDelete] = useState(false);
    const [askEdit, setAskEdit] = useState(false);
    const [formData, setFormData] = useState(false);

    useEffect(() => {
        setAskDelete(false);
    }, []);

    const deleteChange = async () => {
        setAskDelete((currentVal) => !currentVal);
    };

    const deleteAccount = () => {
        // Call API to delete account
        // await ApiHandler(`{account.id}/delete`, "DELETE");
    };
    const editAccount = () => {
        setAskEdit((currentVal) => !currentVal);
        // Call API to edit account
        // await ApiHandler(`{account.id}/edit`, "PUT", formData);
    };

    const handleFormSubmit = async (ev) => {
        ev.preventDefault();
        const form = ev.target;
        setFormData(new FormData(form));
        console.log(formData);
        // await ApiHandler(`{account.id}/edit`, "PUT", formData,);
    };

    const modalEdit = () => {
        return (
            <div className="deleteModal__container">
                <div className="content deleteModal">
                    <span onClick={editAccount} dir="rtl">
                        ✖
                    </span>
                    <p>Modifier vos informations : </p>
                    <form className="" onSubmit={handleFormSubmit}>
                        <div className="login__formItem">
                            <label className="login__formLabel" htmlFor="lastname">
                                Votre nom :
                            </label>
                            <input className="login__formInput" type="text" name="lastname" defaultValue={user.lastname} />
                        </div>
                        <div className="login__formItem">
                            <label className="login__formLabel" htmlFor="firstname">
                                Votre prénom :
                            </label>
                            <input className="login__formInput" type="text" name="firstname" defaultValue={user.firstname} />
                        </div>
                        <div className="login__formItem">
                            <label className="login__formLabel" htmlFor="mail">
                                Votre mail :
                            </label>
                            <input className="login__formInput" type="text" name="mail" defaultValue={user.mail} />
                        </div>
                        <div className="login__formItem">
                            <label className="login__formLabel" htmlFor="pseudo">
                                Votre pseudo :
                            </label>
                            <input className="login__formInput" type="text" name="pseudo" defaultValue={user.pseudo} />
                        </div>
                        <button type="submit" className="main__btn">
                            Enregistrer vos informations
                        </button>
                    </form>
                </div>
            </div>
        );
    };

    const modalDelete = () => {
        return (
            <div className="deleteModal__container">
                <div className="content deleteModal">
                    <span onClick={deleteChange} dir="rtl">
                        ✖
                    </span>
                    <p>Voulez-vous vraiment supprimer votre compte ? </p>
                    <button
                        className="main__btn delete__btn"
                        onClick={() => {
                            deleteAccount();
                        }}
                    >
                        Supprimer votre compte
                    </button>
                </div>
            </div>
        );
    };

    const logout = async () => {
        // Call API to logout
        ApiHandler('logout', 'GET');
        //destroy all sessions existants
        window.location.href = '/';
    };

    const userCard = () => {
        return (
            <div className="userCard">
                <div className="userCard__header">
                    <span className="userCard__pseudo">@{user.pseudo}</span>
                    <button className="userCard__delete" onClick={deleteChange}>
                        <img src="assets/images/bin.svg" alt="Bouton pour supprimer son compte" />
                    </button>
                </div>
                {askDelete && modalDelete()}
                {askEdit && modalEdit()}
                <div className="userCard__main">
                    <img className="userCard__img" src={`/assets/avatars/${user.team}/${user.avatar}.svg`} alt="" />
                    <div className="card__infos">
                        <p>Nom : {user.lastname}</p>
                        <p>Prénom : {user.firstname}</p>
                        <p>Mail : {user.mail}</p>
                        <p>Pseudo : {user.pseudo}</p>
                        <button
                            className="edit"
                            onClick={() => {
                                editAccount();
                            }}
                        >
                            <img src="assets/images/edit.svg" alt="Bouton pour modifier ses informations" />
                        </button>
                    </div>
                </div>
                {user.getFlames > 0 && <img src="assets/icons/flamebadge.png" alt="" className="flameBagde" />}
            </div>
        );
    };

    const infosDisplay = () => {
        return (
            <div className="infosCard__container">
                <h2 className="second__title">Vos cartes gagnées</h2>
                <div className="infosCard__list">
                    <InfosCard />
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="profile__container container">
                <button
                    className="logout"
                    onClick={() => {
                        logout();
                    }}
                >
                    <img src="assets/images/logout.svg" alt="Bouton de déconnexion" />
                </button>
                <h1 className="title profile__title">Votre profil</h1>
                {userCard()}
                <div className="palmares__container">
                    <h2 className="second__title">Votre palmares</h2>
                    <div className="palmares">
                        <div className="palmares__wrapper">
                            <img src="assets/icons/medal.png" alt="nombre de médailles" />
                            <span> x {user.medails} </span>
                        </div>
                        <div className="palmares__wrapper">
                            <img src="assets/icons/medal-gamepoint.png" alt="nombre de points de jeu" />
                            <span> x {user.gamepoints} </span>
                        </div>
                        <div className="palmares__wrapper">
                            <img src="assets/icons/medal-flame.png" alt="nombre d'obtention de la flamme" />
                            <span> x {user.getFlames} </span>
                        </div>
                    </div>
                </div>
                {infosDisplay()}
            </div>
            <img src="assets/images/flame_profile.svg" alt="" className="profile__flamebg" />
            <Menu currentPage="profile" />
        </>
    );
};

export default Profile;

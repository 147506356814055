import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import "./gamesPage.css";
import Menu from "../menu/Menu";
import useAuthContext from "../../hooks/useAuthContext";

const GamesPage = () => {
    const [illuUrl, setIlluUrl] = useState("/assets/illu/archery.png");
    const {userData} = useAuthContext();

    useEffect(() => {
        const today = new Date();
        const startDate = new Date("2024-05-08");
        const endDate = new Date("2024-07-26");
        const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        const totalDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        const intervals = Math.ceil(totalDays / 26);

        const dateDiff = Math.floor(
            (today - startDate) / (1000 * 60 * 60 * 24)
        );
        const periodIndex = Math.floor(dateDiff / 10);
        if (periodIndex % intervals === 0) {
            setIlluUrl("/assets/illu/archery.png");
        } else if (periodIndex % intervals === 1) {
            setIlluUrl("/assets/illu/figure-skating.png");
        } else {
            setIlluUrl("/assets/illu/athletism.png");
        }
    }, []);

    const gameContent = (gameName, gameDesc, urlParam) => {
        //temp
        //user data thx to context
        // call API to get the 2 "game of the day"
        return (
            <Link key={gameName} to={"/" + urlParam}>
                <div className="gameContent">
                    <img
                        src={"/assets/illu/" + urlParam + ".webp"}
                        alt=""
                        className="gameContent__img"
                    />
                    <div className="gameContent__item">
                        <h3 className="gameContent__name">{gameName}</h3>
                        <p className="gameContent__text">{gameDesc}</p>
                    </div>
                </div>
            </Link>
        );
    };

    return (
        <>
            <div className="games">
                <div className="gamesStack">
                    <div className="gamesStack__puce gamesStack__points">
                        {userData && userData.user && (
                            <>
                                <img
                                    src="assets/icons/gamepoint.svg"
                                    alt="points de jeu"
                                />
                                {userData.user.points || 0} {/* userData */}
                            </>
                        )}
                    </div>
                    <div className="gamesStack__puce  gamesStack__flames">
                        {userData && userData.user && (
                            <>
                                <img
                                    src="assets/icons/gameflame.svg"
                                    alt="nombre de fois o� le joueur a eu la flamme"
                                />
                                {userData.user.bearer || 0} {/* userData */}
                            </>
                        )}
                    </div>
                </div>
                <h2 className="games__title second__title">
                    3, 2, 1, Partez !
                </h2>
                <img
                    src={illuUrl}
                    alt="illustration des avatars"
                    className="games__illu"
                />
                <div className="games__window">
                    <span className="game__line"></span>
                    <div className="gameModal">
                        {[
                            gameContent(
                                "Pendu",
                                "D�couvrez les athl�tes des JO24.",
                                "hangman"
                            ),
                            gameContent(
                                "Memory",
                                `Mettez votre m�moire � l'�preuve!`,
                                "memory"
                            ),
                        ]}
                    </div>
                </div>
                <span className="games__infos">
                    ?? Les jeux changent tous les jours � 00h00.
                </span>
                {/* call API GET RANK ON THE COUNTY AND ON ALL USERS */}
            </div>
            <Menu currentPage="games" />
        </>
    );
};

export default GamesPage;
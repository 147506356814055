import RegisterIdentity from "./RegisterIdentity";
import RegisterTeam from "./RegisterTeam";
import RegisterAvatar from "./RegisterAvatar";
import RegisterCounty from "./RegisterCounty";
import RegisterValid from "../registerValid/RegisterValid";
import useRegisterContext from "../../hooks/useRegisterContext";

const RegisterData = () => {
    const {page} = useRegisterContext();

    const display = {
        0: <RegisterIdentity />,
        1: <RegisterTeam />,
        2: <RegisterAvatar />,
        3: <RegisterCounty />,
        4: <RegisterValid />,
    };

    return <div className="register__content">{display[page]}</div>;
};

export default RegisterData;

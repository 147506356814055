import "./infosCard.css";
import useAuthContext from "../../hooks/useAuthContext";

const InfosCard = () => {
    const {answerCards} = useAuthContext();

    return (
        <>
            {answerCards &&
                answerCards.map((answer) => (
                    <div className="infosCard" key={answer.imgUrl}>
                        <div className="infosCard__content">
                            <img
                                src={`assets/cards/${answer.imgUrl}.png`}
                                alt={`Athlète ${answer.name} en action`}
                                className="infosCard__img"
                            />
                            <div className="infosCard__details">
                                <h2 className="infosCard__title">
                                    {answer.name}
                                </h2>
                                <p className="infosCard__id">
                                    {answer.sport} • {answer.birthdate} •
                                    {answer.location}
                                </p>
                                <p className="infosCard__citation">
                                    {answer.citation}
                                </p>
                            </div>
                            <p className="infosCard__desc">
                                {answer.description}
                            </p>
                            <div className="socials">
                                {answer.instagram && (
                                    <a
                                        href={answer.instagram}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src="/assets/icons/instagram.png"
                                            alt="Lien vers le compte Instagram"
                                            className="infosCard__links"
                                        />
                                    </a>
                                )}
                                {answer.facebook && (
                                    <a
                                        href={answer.facebook}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src="/assets/icons/facebook.png"
                                            alt="Lien vers le compte Facebook"
                                            className="infosCard__links"
                                        />
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
        </>
    );
};

export default InfosCard;

import {createContext, useState, useEffect} from "react";

const RegisterContext = createContext();

export const RegisterProvider = ({children}) => {
    const text = {
        0: {title: "Votre identité", btn: "Valider et choisir votre équipe →"},
        1: {title: "Votre équipe", btn: "Valider et choisir votre avatar →"},
        2: {
            title: "Votre avatar",
            btn: "Valider et choisir votre département →",
        },
        3: {title: "Votre département", btn: "Commencer l'aventure →"},
        4: {title: "Votre identité", btn: "Valider et choisir votre équipe →"},
    };

    const [page, setPage] = useState(0);

    const [validPseudo, setValidPseudo] = useState(true);
    const [validLastname, setValidLastname] = useState(true);
    const [validFirstname, setValidFirstname] = useState(true);
    const [validMail, setValidMail] = useState(true);

    const [data, setData] = useState({
        lastname: "",
        firstname: "",
        mail: "",
        pseudo: "",
        password: "",
        team: "Force",
        avatar: "athletism",
        county: "Ain",
    });

    const optPlayer = (name, val) => {
        setData((prevData) => ({
            ...prevData,
            [name]: val,
        }));
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        if (!value) {
        }

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateRegexContext = (regex, val, name) => {
        if (regex.test(val)) {
            if (name === "pseudo") {
                setValidPseudo(true);
            }
        } else {
            if (name === "pseudo") {
                setValidPseudo(false);
            }
        }
    };

    const validateRegex = (regex, e) => {
        if (regex.test(e.target.value)) {
            handleChange(e);
            switch (e.target.name) {
                case "lastname":
                    setValidLastname(true);
                    break;
                case "firstname":
                    setValidFirstname(true);
                    break;
                case "pseudo":
                    setValidPseudo(true);
                    break;
                // case "mail":
                //     setValidMail(true);
                //     break;

                default:
                    break;
            }
        } else {
            switch (e.target.name) {
                case "lastname":
                    setValidLastname(false);
                    break;
                case "firstname":
                    setValidFirstname(false);
                    break;
                case "pseudo":
                    setValidPseudo(false);
                    break;
                // case "mail":
                //     setValidMail(false);
                //     break;

                default:
                    break;
            }
        }
    };

    //const {requiredInput} = data
    //const canSubmit = [...Object.values(requiredinputs)]
    // page === Object.keys(text).length - 1

    useEffect(() => {
        console.log(data);
    }, []);

    return (
        <RegisterContext.Provider
            value={{
                text,
                page,
                setPage,
                data,
                setData,
                handleChange,
                validateRegex,
                validPseudo,
                setValidPseudo,
                optPlayer,
            }}
        >
            {children}
        </RegisterContext.Provider>
    );
};

export default RegisterContext;

import {useRef} from "react";
import useRegisterContext from "../../hooks/useRegisterContext";

function RegisterAvatar() {
    const {optPlayer, data} = useRegisterContext();

    let currentItem = null;
    const listRef = useRef(null);

    const itemLink = (sport) => {
        return `/assets/avatars/${data.team}/${sport}.svg`;
    };

    //get the selected avatar
    const returnItem = (item) => {
        const listNode = listRef.current;
        const itemNode = listNode.querySelectorAll("li");
        itemNode.forEach((element, i) => {
            if (item.index === i) {
                element.classList.add("avatarItem__focus");
                currentItem = element;
            } else {
                element.classList.remove("avatarItem__focus");
            }
            i++;
        });
        //add to data
        optPlayer("avatar", currentItem.attributes.value.nodeValue);
    };

    //temp
    const avatarItem = () => {
        const avatarList = [
            {
                index: 0,
                sportName: `Tir � l'arc`,
                imgSport: `archery`,
                imgAlt: `Avatar en uniforme bleu athl�te tir � l'arc`,
            },
            {
                index: 1,
                sportName: `Athl�tisme`,
                imgSport: `athletism`,
                imgAlt: `Avatar en uniforme bleu athl�te coureur paralympique`,
            },
            {
                index: 2,
                sportName: `Patinage`,
                imgSport: `figure_skating`,
                imgAlt: `Avatar en uniforme bleu patineuse artistique`,
            },
            {
                index: 3,
                sportName: `Escrime`,
                imgSport: `fencing`,
                imgAlt: `Avatar en uniforme bleu escrimeur`,
            },
            {
                index: 4,
                sportName: `Handball`,
                imgSport: `handball`,
                imgAlt: `Avatar en uniforme bleu handballeur`,
            },
        ];

        return (
            <>
                {avatarList.map((item) => {
                    if (item.imgSport === "fencing") {
                        return (
                            <li
                                key={item.sportName}
                                className="avatarItem avatarItem__focus"
                                value={item.imgSport}
                                onClick={() => returnItem(item)}
                            >
                                <p className="avatarItem__name">
                                    {item.sportName}
                                </p>
                                <img
                                    className="avatarItem__img"
                                    src={itemLink(item.imgSport)}
                                    alt={item.imgAlt}
                                />
                            </li>
                        );
                    } else {
                        return (
                            <li
                                key={item.sportName}
                                className="avatarItem"
                                value={item.imgSport}
                                onClick={() => returnItem(item)}
                            >
                                <p className="avatarItem__name">
                                    {item.sportName}
                                </p>
                                <img
                                    className="avatarItem__img"
                                    src={itemLink(item.imgSport)}
                                    alt={item.imgAlt}
                                />
                            </li>
                        );
                    }
                })}
            </>
        );
    };
    return (
        <>
            {data && (
                <ul ref={listRef} className="avatars">
                    {avatarItem()}
                </ul>
            )}
        </>
    );
}

export default RegisterAvatar;
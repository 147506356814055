import "./hangman.css";
import {useState, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import ApiHandler from "../../services/ApiHandler";

const Hangman = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [lost, setLost] = useState(false);
    const [win, setWin] = useState(false);
    const [finish, setFinish] = useState(false);
    const [selectedWord, setSelectedWord] = useState("");
    const [guessedLetters, setGuessedLetters] = useState([]);
    const [remainingAttempts, setRemainingAttempts] = useState(11);
    const letterRefs = useRef({});

    useEffect(() => {
        if (isPlaying) {
            //get a word and init var

            //call API to get one random words
            const words = ["LARROQUE", "FOOTBALL", "SKI"];
            const randomIndex = Math.floor(Math.random() * words.length);
            setSelectedWord(words[randomIndex]);
            setGuessedLetters([]);
            setLost(false);
            setWin(false);
            setFinish(false);

            setRemainingAttempts(11);
            Object.values(letterRefs.current).forEach((ref) => {
                ref.classList.remove("right__letter", "wrong__letter");
            });

            // // Function to check if the player win the game
            // const checkWin = () => {
            //     const wordArray = selectedWord.split("");
            //     const allLettersGuessed = wordArray.every((letter) =>
            //         guessedLetters.includes(letter)
            //     );
            //     if (allLettersGuessed) {
            //         setWin(true);
            //         return true;
            //     }
            //     return allLettersGuessed;
            // };

            // //just the alphabet
            // const alphabet = [
            //     "A",
            //     "B",
            //     "C",
            //     "D",
            //     "E",
            //     "F",
            //     "G",
            //     "H",
            //     "I",
            //     "J",
            //     "K",
            //     "L",
            //     "M",
            //     "N",
            //     "O",
            //     "P",
            //     "Q",
            //     "R",
            //     "S",
            //     "T",
            //     "U",
            //     "V",
            //     "W",
            //     "X",
            //     "Y",
            //     "Z",
            // ];

            // //display game keyboard
            // const generateAlphabet = () => {
            //     return alphabet.map((letter) => (
            //         <li
            //             key={letter}
            //             ref={(element) =>
            //                 (letterRefs.current[letter] = element)
            //             }
            //             className="letter"
            //             onClick={() => handleLetterClick(letter)}
            //         >
            //             {letter}
            //         </li>
            //     ));
            // };

            // // when a letter is clicked, check if it's in the word or not and displaying it
            // const handleLetterClick = (letter) => {
            //     if (guessedLetters.includes(letter)) {
            //         return;
            //     }
            //     if (!selectedWord.includes(letter)) {
            //         setRemainingAttempts((prev) => prev - 1);
            //         letterRefs.current[letter].classList.add("wrong__letter");

            //         // Check for loss condition only if the clicked letter is incorrect
            //         if (remainingAttempts <= 1) {
            //             setRemainingAttempts(0);
            //             setLost(true);
            //             return;
            //         }
            //     } else {
            //         letterRefs.current[letter].classList.add("right__letter");
            //     }
            //     letterRefs.current[letter].classList.add("disabled");
            //     letterRefs.current[letter].setAttribute("disabled", "true");
            //     setGuessedLetters((prevLetters) => {
            //         const newLetters = [...prevLetters, letter];
            //         return newLetters;
            //     });
            //     // Check for win condition
            //     const allLettersGuessed = checkWin();
            //     if (allLettersGuessed) {
            //         setWin(true);
            //     }
            // };
        }
    }, [isPlaying]);

    //#region game logic

    //call API to get the answer infos if there is some > answer card
    const answer = {
        name: `Koumba LARROQUE`,
        sport: `Lutteuse française`,
        birthdate: `22/08/98`,
        location: `Arpajon (91)`,
        description: `Vice-championne du monde à 20 ans, elle participera aux comme lutteuse -68kg, qualification remportée malgré d’importantes blessures.
                    Rayonnante, déterminée et défendre son sport au féminin.`,
        instagram: `https://www.instagram.com/koumba_larroque`,
        facebook: ``,
    };
    //display numbers of letters of the answer
    const generateWordDisplay = () => {
        return selectedWord.split("").map((letter, index) => (
            <span key={index} className="answer__letter">
                {guessedLetters.includes(letter) ? letter : ""}
            </span>
        ));
    };

    //set play true
    const togglePlay = async () => {
        setIsPlaying((currentVal) => !currentVal);
    };

    //rules html before starting the game
    const rules = () => {
        const rulesTxt = `Tentez de deviner un mot en proposant des lettres 
    une par une. Chaque lettre correcte 
    révèle sa position dans le mot, tandis que chaque 
    lettre incorrecte dessine progressivement un pendu. 
    Le jeu se termine lorsque le joueur 
    devine le mot ou lorsque le 
    pendu est complet, représentant une perte.`;
        return (
            <div className="gameContainer">
                <div className="content">
                    <h2 className="content__title">Pendu</h2>
                    <p className="content__rules">{rulesTxt}</p>
                    <span className="content__info">
                        🛈 Les jeux changent tous les jours à 8h00.
                    </span>
                    <button
                        className="content__button main__btn"
                        onClick={togglePlay}
                    >
                        Lancer le jeu →
                    </button>
                </div>
                <div className="ranking__ingame">
                    <p className="ranking__txt">
                        {/* useData */}
                        Votre classement national : 72/148
                    </p>
                    <p className="ranking__txt">
                        {/* useData */}
                        Votre classement départemental : 9/21
                    </p>
                </div>
            </div>
        );
    };

    //just the alphabet
    const alphabet = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
    ];

    //display game keyboard
    const generateAlphabet = () => {
        return alphabet.map((letter) => (
            <li
                key={letter}
                ref={(element) => (letterRefs.current[letter] = element)}
                className="letter"
                onClick={() => handleLetterClick(letter)}
            >
                {letter}
            </li>
        ));
    };

    // Function to check if the player win the game
    const checkWin = () => {
        const wordArray = selectedWord.split("");
        const allLettersGuessed = wordArray.every((letter) =>
            guessedLetters.includes(letter)
        );
        if (allLettersGuessed) {
            setWin(true);
        }
        return allLettersGuessed;
    };

    // when a letter is clicked, check if it's in the word or not and displaying it
    const handleLetterClick = (letter) => {
        if (guessedLetters.includes(letter)) {
            return;
        }
        if (!selectedWord.includes(letter)) {
            setRemainingAttempts((prev) => prev - 1);
            letterRefs.current[letter].classList.add("wrong__letter");

            // Check for loss condition only if the clicked letter is incorrect
            if (remainingAttempts <= 1) {
                setRemainingAttempts(0);
                setLost(true);
                return;
            }
        } else {
            letterRefs.current[letter].classList.add("right__letter");
        }
        letterRefs.current[letter].classList.add("disabled");
        letterRefs.current[letter].setAttribute("disabled", "true");
        setGuessedLetters((prevLetters) => {
            const newLetters = [...prevLetters, letter];
            return newLetters;
        });

        // Check for win condition
        const allLettersGuessed = checkWin();
        if (allLettersGuessed) {
            setWin(true);
        }
    };

    //#endregion

    //#region game modals

    const losingModal = () => {
        return (
            <div className="losingModal">
                <div className="content">
                    <h2 className="content__title">Vous avez perdu...</h2>
                    <img
                        src="/assets/images/losing_hangman.svg"
                        alt="logo flamme avec un visage abattu"
                        className="losing__img"
                    />
                    <p>
                        Oups, il semble que vous n'avez pas réussi à deviner le
                        mot...
                    </p>
                    <Link className="second__btn" to={"/games"}>
                        Retourner aux jeux →
                    </Link>
                </div>
            </div>
        );
    };

    const returnModal = () => {
        return (
            <div className="returnModal">
                <div className="content">
                    <h2 className="content__title">Vous avez gagné !</h2>
                    <img
                        src="/assets/images/logo.png"
                        alt="logo flamme avec un visage heureux"
                        className="losing__img"
                    />
                    {/* call API POST to add the collected points to the player */}
                    <p>🎉 Vous avez gagné x5 jetons 🎉</p>
                    <Link className="second__btn" to={"/games"}>
                        Retourner aux jeux →
                    </Link>
                </div>
            </div>
        );
    };

    const endGame = async () => {
        //call API to increment playPoints for the player
        // ApiHandler("current/5", "POST");
        setFinish((current) => !current);
    };

    // Winning modal
    const winningModal = () => {
        ApiHandler("increment/10", "POST");
        return (
            <div className="winningModal">
                <div className="winningModal__content">
                    <img
                        src="assets/photos/koumba-larroque.png"
                        alt="Athlète Koumba Larroque en action"
                        className="winning__img"
                    />
                    <div className="content__details">
                        <h2 className="winning__title">{answer.name}</h2>
                        <p className="content__id">{answer.identity}</p>
                        <p className="content__citation">{answer.citation}</p>
                    </div>
                    <p className="content__desc"> {answer.description} </p>
                    <div className="socials">
                        {answer.instagram && (
                            <a
                                href={answer.instagram}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src="/assets/icons/instagram.png"
                                    alt="Lien vers le compte Instagram"
                                    className="winning__instaLink"
                                />
                            </a>
                        )}
                        {answer.facebook && (
                            <a
                                href={answer.facebook}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src="/assets/icons/facebook.png"
                                    alt="Lien vers le compte Facebook"
                                    className="winning__fbLink"
                                />
                            </a>
                        )}
                    </div>
                    <span
                        onClick={() => {
                            endGame();
                        }}
                    >
                        Continuer →
                    </span>
                </div>
            </div>
        );
    };
    //#endregion
    const game = () => {
        return (
            <>
                {lost && losingModal()}
                {win && winningModal()}
                {finish && returnModal()}
                <div className="gameContainer">
                    <div className="content">
                        <div className="hangmanHeader">
                            <h2 className="content__title">Pendu</h2>
                            <img
                                src="/assets/images/hangman_head.png"
                                alt="logo flamme avec un visage abattu"
                                className="hangman__img"
                            />
                            {/* <p className="hint">
                                Catégorie : athlète - arts martiaux
                            </p> */}
                        </div>
                        <div className="keyboard">
                            <div className="answer">
                                {generateWordDisplay()}
                            </div>
                            <ul className="letters">{generateAlphabet()}</ul>
                        </div>
                        <p>Remaining attempts: {remainingAttempts}</p>
                    </div>
                </div>
            </>
        );
    };

    return <>{isPlaying === false ? rules() : game()}</>;
};
export default Hangman;

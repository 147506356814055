import useAuthContext from "../../hooks/useAuthContext";

const RankDpt = ({scale}) => {
    const {userData} = useAuthContext();
    //scale = 1 = departemental ranking
    //scale = 2 = national ranking

    // const users = [
    //     {
    //         name: "theo",
    //         team: "force",
    //         score: "126",
    //         medails: "26",
    //     },
    //     {
    //         name: "louis",
    //         team: "force",
    //         score: "124",
    //         medails: "24",
    //     },
    //     {
    //         name: "arthur",
    //         team: "vitesse",
    //         score: "102",
    //         medails: "02",
    //     },
    // ];

    const userDptRender = () => {
        if (userData && userData.topPointDepartment) {
            return userData.topPointDepartment.map((userDpt) => (
                <li key={userDpt.username} className="rank__user">
                    <span>
                        @{userDpt.username} - {userDpt.team}
                    </span>
                    <img
                        src="assets/icons/medal.png"
                        alt="médaille"
                        className="rank__medal"
                    />
                    <span>{userDpt.points} points</span>
                </li>
            ));
        } else {
            return (
                <p>
                    Les données n'ont pas pu s'afficher, veuillez réessayer plus
                    tard.{" "}
                </p>
            );
        }
    };

    const userNtlRender = () => {
        if (userData && userData.topMedailsMondial) {
            return userData.topMedailsMondial.map((userMdl) => (
                <li key={userMdl.username} className="rank__user">
                    <span>
                        @{userMdl.username} - {userMdl.team}
                    </span>
                    <span>{userMdl.medals} médailles</span>
                    <img
                        src="assets/icons/medal.png"
                        alt="médaille"
                        className="rank__medal"
                    />
                </li>
            ));
        } else {
            return (
                <p>
                    Les données n'ont pas pu s'afficher, veuillez réessayer plus
                    tard.
                </p>
            );
        }
    };

    return (
        <div className="ranking__content">
            {scale === 1 ? (
                <>
                    <h2 className="ranking__title">Classement d'hier</h2>
                    <ul className="rank">{userDptRender()}</ul>
                </>
            ) : (
                <>
                    <h2 className="ranking__title">Classement national</h2>
                    <ul className="rank">{userNtlRender()}</ul>
                </>
            )}
        </div>
    );
};

export default RankDpt;

// import "module-alias/register";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/main.css";

import {RegisterProvider} from "./context/RegisterContext";
import {AuthProvider} from "./context/AuthContext";

import Login from "./component/login/Login";
import ErrorPage from "./route/ErrorPage";
import FlameHome from "./component/flameHome/FlameHome";
import Hangman from "./games/hangman/Hangman";
import GamesPage from "./component/gamesPage/gamesPage";
import Register from "./component/register/Register";
import Profile from "./component/profile/Profile";
import HiddenPicture from "./games/hiddenPicture/HiddenPicture";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import MemoryGame from "./games/memory/memory";
import QuizGame from "./games/quiz/quizGame";
import Map from "./component/map/map";
import RegisterValid from "./component/registerValid/RegisterValid";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/login",
        element: <Login />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/home",
        element: <FlameHome />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/flame",
        element: <FlameHome />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/hangman",
        element: <Hangman />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/memory",
        element: <MemoryGame />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/quiz",
        element: <QuizGame />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/games",
        element: <GamesPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/register",
        element: <Register />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/profile",
        element: <Profile />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/hiddenpicture",
        element: <HiddenPicture />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/map",
        element: <Map />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/welcome",
        element: <RegisterValid />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/phpmyadmin",
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <RegisterProvider>
            <AuthProvider>
                <RouterProvider router={router} />
            </AuthProvider>
        </RegisterProvider>
    </React.StrictMode>
);

import "./register.css";
import useRegisterContext from "../../hooks/useRegisterContext";
import RegisterData from "./RegisterData";
import ApiHandler from "../../services/ApiHandler";
import {useNavigate} from "react-router-dom";
import {useEffect, useState, useRef} from "react";

const Register = () => {
    const navigate = useNavigate();
    const [failedRegister, setFailedRegister] = useState(false);
    const [errorData, setErrorData] = useState([]);
    const modalRef = useRef();
    const {page, setPage, data, text} = useRegisterContext();
    const handlePrev = () => setPage((prev) => prev - 1);

    const handleNext = () => setPage((prev) => prev + 1);

    const handleSubmit = async (e) => {
        setErrorData([]);
        const formData = new FormData();
        const newErrorData = [];
        for (const [key, value] of Object.entries(data)) {
            formData.set(key, value);
            if (!value) {
                newErrorData.push(`Le champ ${key} n'est pas valide.`);
            }
        }
        if (newErrorData.length > 0) {
            setErrorData(newErrorData);
            setFailedRegister(true);
        } else {
            const res = await ApiHandler(
                "register",
                "POST",
                formData,
                navigate
            );
            console.log(res);
            console.log(res.success);
            //TODO change res.success to real res bcs idk what the API is sending back ( promise pending ?? )

            if (res.success === true) {
                window.location.href = "/welcome";
            } else {
                newErrorData.push(
                    `Il y a un doublon, l'adresse mail ou le mot de passe est déjà pris.`
                );
                setErrorData(newErrorData);
                setFailedRegister(true);
            }
        }
    };

    const errorModal = () => {
        if (errorData.length > 0) {
            return errorData.map((item, index) => <p key={index}>{item}</p>);
        }
    };
    useEffect(() => {
        setFailedRegister(false);
    }, []);

    const removeModal = () => {
        if (errorData) {
            modalRef.current.classList.add("dnone");
            modalRef.current.classList.remove("error__register");
        }
    };

    const content = (
        <div className="register__container container">
            {failedRegister && (
                <div
                    ref={modalRef}
                    className="error__register"
                    onClick={(e) => {
                        removeModal();
                    }}
                >
                    {errorModal()}
                </div>
            )}
            <div className="register__main">
                <div className="register__header">
                    <h1 className="register__title title">Inscription</h1>
                    <h2 className="register__subtitle second__title">
                        {text[page].title}
                    </h2>
                </div>
                {page !== 0 && (
                    <button
                        onClick={handlePrev}
                        type="button"
                        className="second__btn back__btn"
                    >
                        ← Retour
                    </button>
                )}
                <RegisterData />
                {page === 3 ? (
                    <span
                        type="submit"
                        onClick={handleSubmit}
                        className="second__btn register__btn "
                    >
                        {text[page].btn}
                    </span>
                ) : (
                    <span
                        type="submit"
                        onClick={handleNext}
                        className="second__btn register__btn"
                    >
                        {text[page].btn}
                    </span>
                )}
            </div>
        </div>
    );

    return content;
};

export default Register;

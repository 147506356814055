import "./register.css";
import useRegisterContext from "../../hooks/useRegisterContext";

const RegisterIdentity = () => {
    const {data, handleChange, validateRegex, validPseudo, setValidPseudo} =
        useRegisterContext();

    return (
        /* nom prenom admail pseudo mdp */
        <form action="" method="POST" className="register__form">
            <div className="formItem">
                <label htmlFor="lastname">Votre nom : </label>
                <input
                    autoComplete="off"
                    className="formInput"
                    type="text"
                    name="lastname"
                    required
                    placeholder="De la Fontaine"
                    pattern="^[a-zA-Z0-9]$"
                    value={data.lastname}
                    onChange={(e) => {
                        validateRegex(/^[a-zA-ZÀ-ÖØ-öø-ÿ\-' ]+$/, e);
                    }}
                />
            </div>
            <div className="formItem">
                <label htmlFor="firstname">Votre prénom : </label>
                <input
                    autoComplete="off"
                    className="formInput"
                    type="text"
                    name="firstname"
                    placeholder="Jean"
                    required
                    value={data.firstname}
                    onChange={(e) => {
                        validateRegex(/^[a-zA-ZÀ-ÖØ-öø-ÿ\-' ]+$/, e);
                    }}
                />
            </div>
            <div className="formItem">
                <label htmlFor="mail">Votre adresse-mail : </label>
                <input
                    autoComplete="off"
                    className="formInput"
                    type="text"
                    name="mail"
                    placeholder="jdlf@gmail.com"
                    required
                    value={data.mail}
                    onChange={(e) => {
                        validateRegex(/^[a-zA-ZÀ-ÖØ-öø-ÿ\-'@. ]+$/, e);
                    }}
                />
            </div>
            <div className="formItem">
                <label htmlFor="pseudo">Votre pseudonyme : </label>
                <input
                    autoComplete="off"
                    className="formInput"
                    type="text"
                    name="pseudo"
                    placeholder="Les seuls caractères spéciaux autorisés sont - _ et ."
                    required
                    value={data.pseudo}
                    onChange={(e) => {
                        validateRegex(/^[a-zA-Z0-9\-_.]+$/, e);
                    }}
                />
                {!validPseudo && (
                    <small className="formItem__danger">
                        les seul caractères spéciaux - _ et . sont acceptés
                    </small>
                )}
            </div>
            <div className="formItem">
                <label htmlFor="password">Votre mot de passe :</label>
                <input
                    autoComplete="off"
                    className="formInput"
                    type="password"
                    name="password"
                    placeholder="*********"
                    required
                    onChange={(e) => {
                        validateRegex(/[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]$/, e);
                    }}
                />
            </div>
        </form>
    );
};

export default RegisterIdentity;

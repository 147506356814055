import {useRef} from "react";
import useRegisterContext from "../../hooks/useRegisterContext";

const RegisterTeam = () => {
    const {data, handleChange, optPlayer} = useRegisterContext();
    const blueRef = useRef(null);
    const whiteRef = useRef(null);
    const redRef = useRef(null);

    // const focusHandler = (e) => {
    //     console.log(e.target);
    //     console.log(blueRef);
    // };

    const focusHandler = (e, val) => {
        const teamItem = e.currentTarget;

        console.log(teamItem);
        console.log(val);
        blueRef.current.classList.remove("teamItem__focus");
        whiteRef.current.classList.remove("teamItem__focus");
        redRef.current.classList.remove("teamItem__focus");

        teamItem.classList.add("teamItem__focus");
        optPlayer("team", val);
    };
    const teamItemRender = () => {
        const teams = [
            {
                name: "Force",
                ref: blueRef,
                citation: "La raison du plus fort est toujours la meilleure.",
            },
            {
                name: "Vitesse",
                ref: whiteRef,
                citation: "Rien ne sert de courir, il faut partir à point.",
            },
            {
                name: "Précision",
                ref: redRef,
                citation:
                    " Patience et longueur de temps font plus que force ni que rage.",
            },
        ];

        teams.map((team) => {
            return (
                <div
                    ref={team.ref}
                    onClick={(e) => {
                        focusHandler(e, team.name);
                    }}
                    onChange={handleChange}
                    className="teamItem teamItem__focus"
                >
                    <div className="teamItem__header">
                        <h3 className="">
                            Équipe
                            <span className="teamItem__title ">
                                {" "}
                                {team.item}{" "}
                            </span>
                        </h3>
                        <span className="teamItem__color team__blue">
                            {team.citation}
                        </span>
                    </div>
                    <p className="teamItem__citation"></p>
                </div>
            );
        });
    };

    return (
        <div className="register__team">
            <div
                ref={blueRef}
                onClick={(e) => {
                    focusHandler(e, "Force");
                }}
                onChange={handleChange}
                className="teamItem teamItem__focus"
            >
                <div className="teamItem__header">
                    <h3 className="">
                        Équipe
                        <span className="teamItem__title "> Force</span>
                    </h3>
                    <span className="teamItem__color team__blue"></span>
                </div>
                <p className="teamItem__citation">
                    La raison du plus fort est toujours la meilleure.
                </p>
            </div>
            <div
                ref={whiteRef}
                onClick={(e) => {
                    focusHandler(e, "Vitesse");
                }}
                onChange={handleChange}
                className="teamItem"
            >
                <div className="teamItem__header">
                    <h3 className="">
                        Équipe
                        <span className="teamItem__title"> Vitesse</span>
                    </h3>
                    <span className="teamItem__color team__white"></span>
                </div>
                <p className="teamItem__citation">
                    Rien ne sert de courir, il faut partir à point.
                </p>
            </div>
            <div
                ref={redRef}
                onClick={(e) => {
                    focusHandler(e, "Précision");
                }}
                onChange={handleChange}
                className="teamItem"
            >
                <div className="teamItem__header">
                    <h3 className="">
                        Équipe
                        <span className="teamItem__title"> Précision</span>
                    </h3>
                    <span className="teamItem__color team__red"></span>
                </div>
                <p className="teamItem__citation">
                    Patience et longueur de temps font plus que force ni que
                    rage.
                </p>
            </div>
        </div>
    );
};

export default RegisterTeam;

export const dataQuiz = [
    {
      id: 1,
      question: "Où sont nés les Jeux Paralympiques : en Grèce, en Angleterre ou en Chine ?",
      options: ["En Grèce", "En Angleterre", "En Chine"],
      answer: "En Angleterre"
    },
    {
      id: 2,
      question: "Vrai ou faux ? Le dopage est une pratique dangereuse pour la santé qui consiste à absorber des substances permettant d’augmenter ses capacités sportives.",
      options: ["Vrai (et c’est puni par la loi)", "Faux (le dopage n'est pas dangereux)", "Faux (le dopage n'est pas puni par la loi)"],
      answer: "Vrai (et c’est puni par la loi)"
    },
    {
      id: 3,
      question: "Dans quel célèbre stade se dérouleront les épreuves d’athlétisme, de Para athlétisme et de rugby ?",
      options: ["Au Stade de France, à Saint-Denis (au nord de Paris)", "Au Stade Olympique de Berlin", "Au Stade Santiago Bernabeu à Madrid"],
      answer: "Au Stade de France, à Saint-Denis (au nord de Paris)"
    },
    {
        id: 4,
        question: " Combien de fois la célèbre athlète française Marie-José Perec a-t-elle été championne olympique ?",
        options: ["2 fois", "5 fois", "3 fois"],
        answer: "3 fois"
    },
    {
        id: 4,
        question: "  Que signifie l’expression anglaise « Ready, steady, go! » employée au début des courses ?",
        options: ["« Prêt, feu, partez ! »", "« Prêt, prêt, prêt ! »", "« À vos marques, prêts, partez ! »"],
        answer: "« À vos marques, prêts, partez ! »"
    },
    {
        id: 5,
        question: "Dans quel sport sur glace Guillaume Cizeron fait-il équipe depuis l’âge de 9 ans avec Gabriella Papadakis ?",
        options: ["Le patinage artistique", "Le hockey sur glace", "Le patinage de vitesse"],
        answer: "Le patinage artistique"
      },
      {
        id: 6,
        question: "Vrai ou faux ? Plus de 1 000 personnes seront mobilisées pendant les Jeux de Paris pour effectuer des contrôles antidopage auprès des sportifs.",
        options: ["Vrai", "Faux", "Impossible de savoir"],
        answer: "Vrai"
      },
      {
        id: 7,
        question: "Combien de fois Emmanuelle Mörch a-t-elle le droit de faire rebondir la balle avant de la frapper lors de ses matchs de tennis fauteuil ?",
        options: ["4 fois", "2 fois", "6 fois"],
        answer: "2 fois"
      },
      {
        id: 8,
        question: "Vrai ou faux ? Les 4 piliers de la philosophie olympique sont le respect de soi et de son corps, des autres, des règles et de l’environnement.",
        options: ["Vrai", "Faux", "Les piliers sont différents"],
        answer: "Vrai"
      },
      {
        id: 9,
        question: "Quel est le pourcentage des sites de compétition des Jeux de Paris 2024 qui seront desservis en transports en commun ?",
        options: ["100 %", "75 %", "50 %"],
        answer: "100 %"
      },
      {
        id: 10,
        question: "Quel célèbre boxeur est aussi connu pour son combat pour l’égalité des droits des Noirs américains : Mike Tyson, Joe Frazier ou Mohamed Ali ?",
        options: ["Mike Tyson", "Joe Frazier", "Mohamed Ali"],
        answer: "Mohamed Ali"
      },
      {
        id: 11,
        question: "Que recevait le vainqueur d’une épreuve des Jeux antiques : une médaille, un vase ou une couronne de feuillage ?",
        options: ["Une couronne de feuillage (en olivier, pin et laurier. Aujourd’hui, les vainqueurs reçoivent une médaille)", "Une médaille", "Un vase"],
        answer: "Une couronne de feuillage (en olivier, pin et laurier. Aujourd’hui, les vainqueurs reçoivent une médaille)"
      },
      {
        id: 12,
        question: "Vrai ou faux ? Le nageur français Alain Bernard a obtenu une médaille d’or aux Jeux de Londres, en 2012.",
        options: ["Vrai (au relais 4 x 100 m nage libre)", "Faux", "Il a obtenu une médaille d'argent"],
        answer: "Vrai (au relais 4 x 100 m nage libre)"
      },
      {
        id: 13,
        question: "Que reçoivent les 8 premiers athlètes de chaque épreuve : de l’argent, un diplôme ou une médaille en chocolat ?",
        options: ["Un diplôme (ce diplôme olympique ou paralympique est décerné aux 8 premiers pour récompenser leur performance)", "De l'argent", "Une médaille en chocolat"],
        answer: "Un diplôme (ce diplôme olympique ou paralympique est décerné aux 8 premiers pour récompenser leur performance)"
      },
      {
        id: 14,
        question: "Vrai ou faux ? Il y a eu des Jeux Olympiques pendant les 2 Guerres mondiales.",
        options: ["Faux (pas en 1916, ni en 1940, ni en 1944)", "Vrai", "Ils ont eu lieu pendant les deux guerres"],
        answer: "Faux (pas en 1916, ni en 1940, ni en 1944)"
      },
      {
        id: 15,
        question: "Quel sport, dans lequel brille la Française Anaïs Bescond, combine 2 disciplines : le ski de fond et le tir ?",
        options: ["Le biathlon", "Le ski alpin", "Le patinage artistique"],
        answer: "Le biathlon"
      },
      {
        id: 16,
        question: "Combien de fois le skieur français Jean-Claude Killy a-t-il remporté des médailles d'or aux Jeux olympiques d'hiver : 1 fois, 2 fois ou 3 fois ?",
        options: ["3 fois (en 1968 à Grenoble, sur les épreuves de descente, slalom géant et slalom spécial)", "1 fois", "2 fois"],
        answer: "3 fois (en 1968 à Grenoble, sur les épreuves de descente, slalom géant et slalom spécial)"
      },
      {
        id: 17,
        question: "Dans quel sport les épreuves se déroulent-elles sur une piste ovale de 400 mètres de long, avec des virages relevés à 42 degrés : le patinage de vitesse, le curling ou le hockey sur glace ?",
        options: ["Le patinage de vitesse", "Le curling", "Le hockey sur glace"],
        answer: "Le patinage de vitesse"
      },
      {
        id: 18,
        question: "Vrai ou faux ? Les Jeux Olympiques et les Jeux Paralympiques se déroulent chaque année en alternance.",
        options: ["Faux", "Vrai", "Les deux événements se déroulent en même temps"],
        answer: "Faux"
      },
      {
        id: 19,
        question: "Quel est le nom de la flamme allumée lors de la cérémonie d'ouverture des Jeux olympiques : la flamme éternelle, la flamme olympique ou la flamme sacrée ?",
        options: ["La flamme olympique", "La flamme éternelle", "La flamme sacrée"],
        answer: "La flamme olympique"
      },
      {
        id: 20,
        question: "Qui a été le premier homme à courir le marathon en moins de 2 heures : Eliud Kipchoge, Haile Gebrselassie ou Kenenisa Bekele ?",
        options: ["Eliud Kipchoge", "Haile Gebrselassie", "Kenenisa Bekele"],
        answer: "Eliud Kipchoge"
      },
      {
        id: 21,
        question: "Dans quel sport le Français Pierre de Coubertin est-il souvent associé en tant que fondateur des Jeux Olympiques modernes : la natation synchronisée, l'aviron ou l'escrime ?",
        options: ["L'escrime", "La natation synchronisée", "L'aviron"],
        answer: "L'escrime"
      },
      {
        id: 22,
        question: "Quelle est la devise des Jeux Olympiques : « Plus vite, plus haut, plus fort », « L'esprit, le corps, l'âme » ou « Union, discipline, respect » ?",
        options: ["« Plus vite, plus haut, plus fort »", "« L'esprit, le corps, l'âme »", "« Union, discipline, respect »"],
        answer: "« Plus vite, plus haut, plus fort »"
      },
      {
        id: 23,
        question: "Combien de continents sont représentés lors des Jeux Olympiques : 3, 5 ou 7 ?",
        options: ["5", "3", "7"],
        answer: "5"
      },
      {
        id: 24,
        question: "Dans quelle ville les premiers Jeux Olympiques modernes ont-ils eu lieu en 1896 : Rome, Athènes ou Londres ?",
        options: ["Athènes", "Rome", "Londres"],
        answer: "Athènes"
      },
      {
        id: 25,
        question: "Qui est l'athlète américaine surnommée 'The Flying Squirrel' qui a remporté plusieurs médailles d'or en gymnastique artistique : Simone Biles, Gabby Douglas ou Aly Raisman ?",
        options: ["Gabby Douglas", "Simone Biles", "Aly Raisman"],
        answer: "Gabby Douglas"
      },
      {
        id: 26,
        question: "Quelle est la durée approximative d'un match de hockey sur gazon olympique : 60 minutes, 90 minutes ou 120 minutes ?",
        options: ["60 minutes", "90 minutes", "120 minutes"],
        answer: "60 minutes"
      },
      {
        id: 27,
        question: "Vrai ou faux ? Les anneaux olympiques représentent les cinq continents : Afrique, Amérique, Asie, Europe et Océanie.",
        options: ["Vrai", "Faux, ils représentent les cinq océans", "Faux, ils représentent les cinq sens"],
        answer: "Vrai"
      },
      {
        id: 28,
        question: "Quel est le pays qui a remporté le plus grand nombre de médailles d'or aux Jeux Olympiques d'été : États-Unis, Russie ou Chine ?",
        options: ["États-Unis", "Russie", "Chine"],
        answer: "États-Unis"
      },
      {
        id: 29,
        question: "Dans quelle discipline sportive la France a-t-elle remporté le plus grand nombre de médailles d'or aux Jeux Olympiques d'été : escrime, natation ou cyclisme ?",
        options: ["Escrime", "Natation", "Cyclisme"],
        answer: "Escrime"
      },
      {
        id: 30,
        question: "Quel pays a organisé les Jeux Olympiques d'été en 2016 : Australie, Brésil ou Espagne ?",
        options: ["Brésil", "Australie", "Espagne"],
        answer: "Brésil"
      },
      {
        id: 31,
        question: "Quel est le nom de la mascotte des Jeux Olympiques de Tokyo 2020 : Miraitowa, Soohorang ou Vinicius ?",
        options: ["Miraitowa", "Soohorang", "Vinicius"],
        answer: "Miraitowa"
      },
      {
        id: 32,
        question: "Dans quel sport le Jamaïcain Usain Bolt est-il devenu célèbre en remportant plusieurs médailles d'or olympiques : le basketball, l'athlétisme ou le tennis ?",
        options: ["L'athlétisme", "Le basketball", "Le tennis"],
        answer: "L'athlétisme"
      },
      {
        id: 33,
        question: "Vrai ou faux ? Le saut à la perche est une épreuve olympique de l'athlétisme.",
        options: ["Vrai", "Faux, c'est une épreuve de gymnastique", "Faux, c'est une épreuve de natation synchronisée"],
        answer: "Vrai"
      },
      {
        id: 34,
        question: "Quel est le symbole des Jeux Paralympiques : une flamme, une étoile ou des anneaux entrelacés ?",
        options: ["Des anneaux entrelacés", "Une flamme", "Une étoile"],
        answer: "Des anneaux entrelacés"
      },
      {
        id: 35,
        question: "Combien de médailles d'or a remportées la nageuse américaine Michael Phelps au cours de sa carrière olympique : 18, 22 ou 28 ?",
        options: ["23", "18", "28"],
        answer: "23"
      },
      {
        id: 36,
        question: "Quel est le nom de la compétition qui réunit les meilleurs skieurs et snowboardeurs dans diverses disciplines extrêmes : X Games, Extreme Olympics ou Snowlympics ?",
        options: ["X Games", "Extreme Olympics", "Snowlympics"],
        answer: "X Games"
      },
      {
        id: 37,
        question: "Dans quel sport la légende américaine Serena Williams a-t-elle remporté plusieurs médailles d'or olympiques : le tennis, le golf ou le rugby ?",
        options: ["Le tennis", "Le golf", "Le rugby"],
        answer: "Le tennis"
      },
      {
        id: 38,
        question: "Quel est le nom du stade emblématique où se déroulent les finales de la Coupe du Monde de football et les compétitions d'athlétisme : Stade Santiago Bernabéu, Stade Maracanã ou Stade Wembley ?",
        options: ["Stade Maracanã", "Stade Santiago Bernabéu", "Stade Wembley"],
        answer: "Stade Maracanã"
      },
      {
        id: 39,
        question: "Vrai ou faux ? Les Jeux Olympiques d'hiver comprennent des sports comme le curling et le patinage artistique.",
        options: ["Vrai", "Faux, les sports d'hiver comprennent uniquement le ski et le snowboard", "Faux, les Jeux Olympiques d'hiver ne comprennent pas le curling"],
        answer: "Vrai"
      }
        
];
export default dataQuiz;
  
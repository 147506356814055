import React, {useState, useEffect} from "react";
import {MapContainer, TileLayer, Marker, Popup} from "react-leaflet";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import {Button, Modal} from "react-bootstrap";
import L from "leaflet";
import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarker} from "@fortawesome/free-solid-svg-icons";
import {motion} from "framer-motion";
import {renderToString} from "react-dom/server";
import "./map.css";
import Menu from "../menu/Menu";
import ApiHandler from "../../services/ApiHandler";

library.add(faMapMarker);

const Map = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locations, setLocations] = useState([]);
    const [flamePosition, setFlamePosition] = useState(null);
    const [notStarted, setNotStarted] = useState(null);
    const [course, setCourse] = useState(null);
    //temp
    //API init get the map data
    const mapData = {
        currentPlace: "Manosque",
        county: "Alpes-de-Haute-Provence",
        destination: "Arles",
    };
    const user = {
        username: "test",
        team: "Vitesse",
        avatar: "fencing",
        county: "Alpes-de-Haute-Provence",
    };

    useEffect(() => {
        // const fetchLocations = async () => {
        //     try {
        //         const response = await axios.get(
        //             "https://maksance.alwaysdata.net/api-jo/etapes"
        //         );
        //         const locationsWithUserDetails = response.data.data.map(
        //             (location) => ({
        //                 ...location,
        //                 username: user.username,
        //                 avatar: user.avatar,
        //                 team: user.team,
        //             })
        //         );
        //         setLocations(locationsWithUserDetails);
        //         console.log(locations);
        //     } catch (error) {
        //         console.error("Error fetching locations:", error);
        //     }
        // };
        // const fetchSteps = async () => {
        //     try {
        //         const data = await ApiHandler("steps", "GET");
        //         setLocations(data.steps);
        //         console.log(locations);
        //     } catch (error) {
        //         console.error("Error fetching steps:", error);
        //     }
        // };
        // fetchLocations();
        // fetchSteps();
    }, []);
    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const tempCourse = await ApiHandler("map", "GET");
                if (tempCourse && tempCourse.actualStep) {
                    setNotStarted(false);
                    setCourse(tempCourse);
                } else {
                    setNotStarted(true);
                }
            } catch (error) {
                console.log("error fetching map course :", error);
            }
        };

        fetchCourse();
    }, []);

    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const response = await axios.get(
                    "https://maksance.alwaysdata.net/api-jo/etapes"
                );
                setLocations(response.data.data);
            } catch (error) {
                console.error("Error fetching locations:", error);
            }
        };

        fetchLocations();
    }, []);

    useEffect(() => {
        const updateFlamePosition = () => {
            if (locations.length > 0) {
                const nextLocation = locations[0];
                setFlamePosition({
                    lat: nextLocation.geolocalisation.latitude,
                    lng: nextLocation.geolocalisation.longitude,
                });
            }
        };
        updateFlamePosition();
    }, [locations]);

    const handleMarkerClick = (location) => {
        console.log("HANDLEMARKERCLCK");
        setSelectedLocation(location);
        console.log(location);
        console.log(selectedLocation);
        setShowModal(true);
    };

    const customMarkerIcon = L.divIcon({
        className: "custom-icon",
        html: renderToString(<FontAwesomeIcon icon="map-marker" size="lg" />),
    });

    return (
        <>
            <header className="header header__map">
                <h1 className="second__title games__title map__title">
                    Le parcours
                </h1>
                <p className="header__txt">
                    {notStarted === true && (
                        <>
                            Le relais de la flamme commencera le 8 mai à{" "}
                            <span className="bold"> Marseille</span>.
                        </>
                    )}
                    {course && course.actualStep && (
                        <>
                            La flamme part de
                            <span className="bold">
                                {" "}
                                {course.actualStep}{" "}
                            </span>{" "}
                            en direction de
                            <span className="bold"> {course.nextStep} </span>.
                        </>
                    )}
                </p>
            </header>
            <MapContainer
                center={[46.603354, 1.888334]}
                zoom={6}
                style={{
                    height: "40vh",
                    borderRadius: "0.5rem",
                    margin: "1rem",
                }}
            >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {locations &&
                    locations.map((location) => (
                        <Marker
                            key={location.etape_numero}
                            position={[
                                location.geolocalisation.latitude,
                                location.geolocalisation.longitude,
                            ]}
                            icon={customMarkerIcon}
                            onClick={() => handleMarkerClick(location)}
                        >
                            <Popup>
                                Date : {location.date} <br /> Etape :
                                {location.etape_numero} <br /> Ville :
                                {location.ville} <br /> Population :
                                {location.population}
                                {location.username && (
                                    <>
                                        <p>
                                            Félicitations à{" "}
                                            <span className="bold">
                                                {" "}
                                                @{location.etape}{" "}
                                            </span>{" "}
                                            pour avoir porté la flamme jusqu'ici
                                            !
                                        </p>
                                        <img
                                            src={`/assets/avatars/${location.etape}/${location.etape}.svg`}
                                            alt="avatar du joueur mis en avant"
                                        />
                                    </>
                                )}
                            </Popup>
                        </Marker>
                    ))}
                {flamePosition && (
                    <motion.div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            pointerEvents: "none",
                        }}
                        initial={false}
                        animate={{
                            scale: [0.5, 1, 0.5],
                            opacity: [0.5, 1, 0.5],
                            x: flamePosition.lng,
                            y: flamePosition.lat,
                        }}
                        transition={{
                            repeat: Infinity,
                            duration: 3,
                            ease: "linear",
                        }}
                    ></motion.div>
                )}
            </MapContainer>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                {selectedLocation && (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>{selectedLocation.ville}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Étape: {selectedLocation.etape}</p>
                            <p>Date: {selectedLocation.date}</p>
                            <p>Population: {selectedLocation.population}</p>
                            {selectedLocation.etape && (
                                <>
                                    <p>
                                        Félicitations à
                                        <span className="bold">
                                            @{selectedLocation.username}
                                        </span>{" "}
                                        pour avoir porté la flamme jusqu'ici !
                                    </p>
                                    <p>
                                        Félicitations à
                                        <span className="bold">
                                            @{selectedLocation.etape}
                                        </span>{" "}
                                        pour avoir porté la flamme jusqu'ici !
                                    </p>
                                    <img
                                        src={`/assets/avatars/${selectedLocation.etape}/${selectedLocation.etape}.svg`}
                                        alt="avatar du joueur mis en avant"
                                    />
                                    {/* Ajoutez d'autres détails si nécessaire */}
                                </>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => setShowModal(false)}
                            >
                                Fermer
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Modal>
            <Menu currentPage="map" />
        </>
    );
};

export default Map;

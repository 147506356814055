import React, { useState, useEffect } from 'react';
import dataQuiz from '../data/dataQuiz';
import './quiz.css';

const QuizGame = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [timer, setTimer] = useState(20);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [currentQuestion]);

  useEffect(() => {
    if (timer === 0) {
      goToNextQuestion();
    }
  }, [timer]);

  const handleOptionClick = (option) => {
    if (option === dataQuiz[currentQuestion].answer) {
      setScore(score + 1);
    }
    setCurrentAnswer(option);
    setTimeout(goToNextQuestion, 1000);
  };

  const goToNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < dataQuiz.length) {
      setCurrentQuestion(nextQuestion);
      setCurrentAnswer(null);
      setTimer(20);
    } else {
      alert(`Quiz terminé ! Votre score final est : ${score}/${dataQuiz.length}`);
    }
  };

  return (
    <div className="quiz-container">
        <br />
      <h1>Quiz sur les Jeux Olympiques</h1>
      <br />
      {currentQuestion < dataQuiz.length && (
        <>
          <h2>Question {currentQuestion + 1}</h2>
          <div className="timer-container">
            <div className="timer-circle">
                <span className="timer-text">{timer}</span>
            </div>
          </div>
          <br /><br />
          <p>{dataQuiz[currentQuestion].question}</p>
          <br />
          <div className="options">
            {dataQuiz[currentQuestion].options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleOptionClick(option)}
                className={`option-button ${
                  currentAnswer === option
                    ? option === dataQuiz[currentQuestion].answer
                      ? 'correct'
                      : 'incorrect'
                    : ''
                }`}
                disabled={currentAnswer !== null}
              >
                {option}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default QuizGame;

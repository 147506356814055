import {useState, useEffect} from "react";
import useRegisterContext from "../../hooks/useRegisterContext";
import ApiHandler from "../../services/ApiHandler";

function RegisterCounty() {
    const {optPlayer, submit} = useRegisterContext();
    const [selectedCounty, setSelectedCounty] = useState("Ain");
    const [countys, setCountys] = useState([]);
    useEffect(() => {
        const fetchCounties = async () => {
            const tempCountys = await ApiHandler("countys", "GET");
            console.log(tempCountys);
            if (!countys.error) setCountys(tempCountys);
        };

        fetchCounties();
    }, []);
    //temp
    const counties = [
        {
            id: 0,
            name: `Ain`,
        },
        {
            id: 1,
            name: `Aisne`,
        },
        {
            id: 2,
            name: `C�te d'Or`,
        },
        {
            id: 3,
            name: `Ard�ches`,
        },
    ];

    // const countiesData = ApiHandler("countys", "POST");
    // console.log(countiesData);

    const countyData = (val) => {
        setSelectedCounty(val);
        optPlayer("county", val);
    };

    // call API to get all counties
    return (
        <div className="register__county">
            <select
                name="county"
                className="select__county"
                id="select__county"
                value={selectedCounty}
                onChange={(e) => countyData(e.target.value)}
            >
                <option className="option__county" value="">
                    Choisissez votre d�partement
                </option>
                {countys &&
                    countys.map((item) => {
                        return (
                            <option
                                className="option__county"
                                key={item.id}
                                value={item.step_name}
                            >
                                {item.step_name}
                            </option>
                        );
                    })}
            </select>
            <div className="countyInfo">
                <p className="countyInfo__txt">
                    Pour votre information, il y a deux flammes dans le jeu : la
                    flamme d�partement et la flamme olympqiue. Vous ne pouvez
                    recevoir la flamme olympique du jeu seulement lorsque la
                    flamme en temps r�el sera dans votre d�partement. Si la
                    flamme ne passe pas par votre d�partement, choisissez le
                    plus proche de chez vous pour pouvoir jouer quand m�me ! Les
                    d�partements qui ne recevront pas la flamme sont :
                </p>
                <p className="countyInfo__list">
                    Ain (01), Ard�che (07), Ardennes (08), Ari�ge (09),
                    Charente-Maritime (17), Cher (18), Corr�ze (19),
                    C�tes-d'Armor (22), Creuse (23), Dordogne (24), Doubs (25),
                    Dr�me (26), Gard (30), Haute-Garonne (31), Gers (32),
                    Gironde (33), H�rault (34), Hautes-Alpes (05), Haute-Sa�ne
                    (70), Haute-Savoie (74), Haute-Vienne (87), Hautes-Pyr�n�es
                    (65), Haut-Rhin (68), Hauts-de-Seine (92), Is�re (38), Jura
                    (39), Landes (40), Loire (42), Lot (46), Lot-et-Garonne
                    (47), Loz�re (48), Meurthe-et-Moselle (54), Ni�vre (58),
                    Orne (61), Puy-de-D�me (63), Rh�ne (69), Sa�ne-et-Loire
                    (71), Sarthe (72), Savoie (73), Seine-et-Marne (77),
                    Seine-Maritime (76), Tarn (81), Tarn-et-Garonne (82),
                    Territoire de Belfort (90), Vaucluse (84), Vienne (86),
                    Vosges (88).
                </p>
            </div>
        </div>
    );
}

export default RegisterCounty;
import { createContext, useState, useEffect } from 'react';
import ApiHandler from '../services/ApiHandler';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [flameSize, setFlameSize] = useState(null);
    const [randomGames, setRandomGames] = useState(null);

    let tempSize = null;
    const answerCards = [
        {
            name: `Koumba LARROQUE`,
            imgUrl: `koumba-larroque`,
            sport: `Lutteuse`,
            birthdate: `22/08/1998`,
            location: `Arpajon (91)`,
            description: `Vice-championne du monde à 20 ans, elle participera aux comme lutteuse -68kg, qualification remportée malgré d'importantes blessures.
                        Rayonnante, déterminée et défendre son sport au féminin.`,
            instagram: `https://www.instagram.com/koumba_larroque`,
            facebook: ``,
        },
        {
            name: `Teddy RINER`,
            imgUrl: `teddy-riner`,
            sport: `Judoka`,
            birthdate: `07/04/1989`,
            location: `Guadeloupe`,
            description: `11 fois champion du monde et triple champion olympique, c'est le judoka préféré des  Français ! 
                        A 35 ans, il reste dans la compétition et n'a toujours pas fini d'impressionner.`,
            instagram: `https://www.instagram.com/teddyriner`,
            facebook: `https://www.facebook.com/riner.teddy`,
        },
        {
            name: `Enzo LEFORT`,
            imgUrl: `enzo-lefort`,
            sport: `Escrimeur`,
            birthdate: `29/09/1991`,
            location: `Guadeloupe`,
            description: `Il débute à 5 ans grâce à Laura Flessel, Escrimeuse renommée qui est aussi guadeloupéenne. Très actif, il obtient son diplôme en kinésithérapie et se passionne pour la photo et la mode. 
                        Très sensible, il lui tient à coeur de mettre en avant la diversité de son sport qui reste dans l'imaginaire collectif réservé à une élite sociale.`,
            instagram: `https://www.instagram.com/enzo_lefort`,
            facebook: `https://www.facebook.com/enzo.lefort`,
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ApiHandler('init', 'POST');
                const games = await ApiHandler('randomGames', 'GET');
                console.log(data);
                setRandomGames(games);
                setUserData(data);
                setFlameSize('middle');
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        console.log(userData);
    }, [userData]);

    return <AuthContext.Provider value={{ userData, flameSize, answerCards, randomGames }}>{children}</AuthContext.Provider>;
};

export default AuthContext;

import React, {useEffect, useCallback} from "react";
import {usePwa} from "@dotmind/react-use-pwa";

const PwaInstall = () => {
    const {installPrompt, isInstalled, isStandalone, isOffline, canInstall} =
        usePwa();

    const handleInstallPrompt = useCallback(() => {
        if (canInstall) {
            installPrompt();
        }
    }, [canInstall, installPrompt]);

    useEffect(() => {
        // Check if the PWA is installed and standalone after the component mounts
        if (isInstalled || isStandalone) {
            // You might want to redirect or perform other actions here
        }
    }, [isInstalled, isStandalone]);

    if (isOffline) {
        return <p>Pas de connexion 📶</p>;
    }

    if (!isInstalled && !isStandalone) {
        return (
            <button className="pwa__btn" onClick={handleInstallPrompt}>
                <span className="second__btn">Installer l'application 🔥</span>
            </button>
        );
    }
};

export default PwaInstall;

import {useRouteError, Link} from "react-router-dom";
import React from "react";
import "./errorPage.css";

const ErrorPage = () => {
    const error = useRouteError();
    console.error(error);
    return (
        <div className="error container">
            <img src="/assets/images/losing_hangman.svg" alt="" />
            <h1 className="error__title title">Erreur {error.status}</h1>
            {error.status === 404 ? (
                <p className="error__txt">Désolé, cette page n'existe pas !</p>
            ) : error.status === 403 ? (
                <p className="error__txt">
                    Vous n'avez pas accès à cette page !
                </p>
            ) : (
                <p className="error__spec">
                    <i>{error.statusText || error.message}</i>
                </p>
            )}
            <Link className="error__link link second__btn" to="/home">
                Retour à la page d'acceuil
            </Link>
        </div>
    );
};

export default ErrorPage;

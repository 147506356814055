import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import ScratchCardComponent from "./ScratchCard";
import "./hiddenPicture.css";
// import CanvaRemove from "../../utils/CanvaRemove";

function HiddenPicture() {
    const [isPlaying, setIsPlaying] = useState(false);
    const [lost, setLost] = useState(false);
    const [win, setWin] = useState(false);
    const [finish, setFinish] = useState(false);
    const [selectedWord, setSelectedWord] = useState("");

    useEffect(() => {
        if (isPlaying) {
            //get a word and init var

            //call API to get one random photo
            const words = [
                "hockey-sur-gazon",
                "teddy-riner",
                "arnaud-assoumani",
            ];
            const randomIndex = Math.floor(Math.random() * words.length);
            setSelectedWord(words[randomIndex]);
            setLost(false);
            setWin(false);
            setFinish(false);
        }
    }, [isPlaying]);

    //#region game logic

    //call API to get the answer infos if there is some > answer card
    const answer = {
        name: `Koumba LARROQUE`,
        sport: `Lutteuse française`,
        birthdate: `22/08/98`,
        location: `Arpajon (91)`,
        citation: `Marquer mon histoire et marquer l'histoire de la lutte. Simplement une belle médaille d'or, j'espère.`,
        description: `Vice-championne du monde à 20 ans, elle participera aux comme lutteuse -68kg, qualification remportée malgré d’importantes blessures.
                    Rayonnante, déterminée et défendre son sport au féminin.`,
        instagram: `https://www.instagram.com/koumba_larroque`,
        facebook: ``,
    };
    //set play true
    const togglePlay = async () => {
        setIsPlaying((currentVal) => !currentVal);
    };

    //rules html before starting the game
    const rules = () => {
        const rulesTxt = `Une image se cache derrière un écran noir. Vous avez le droit
        à trois touches sur l'écran pour avoir un apercu de l'image cachée. Avec seulement
        3 essais, vous devez ensuite deviner le mot que l'image représente : ça peut être 
        un athlète, un lieu, un sport, une association ou encore un équipement ! `;
        return (
            <div className="gameContainer">
                <div className="content">
                    <h2 className="content__title">Image camouflée</h2>
                    <p className="content__rules">{rulesTxt}</p>
                    <span className="content__info">
                        🛈 Les jeux changent tous les jours à 8h00.
                    </span>
                    <button
                        className="content__button main__btn"
                        onClick={togglePlay}
                    >
                        Lancer le jeu →
                    </button>
                </div>
                <div className="ranking__ingame">
                    <p className="ranking__txt">
                        Votre classement national : 72/148
                    </p>
                    <p className="ranking__txt">
                        Votre classement départemental : 9/21
                    </p>
                </div>
            </div>
        );
    };

    const handleInputChange = (e) => {
        let val = e.target.value.replace(/ /g, "");
        let word = selectedWord.replace(/-/g, "");
        console.log(val, word);
        if (val === word) {
            setWin(true);
        }
    };
    //#endregion game logic

    //#region game modals

    const losingModal = () => {
        return (
            <div className="losingModal">
                <div className="content">
                    <h2 className="content__title">Vous avez perdu...</h2>
                    <img
                        src="/assets/images/losing_hangman.svg"
                        alt="logo flamme avec un visage abattu"
                        className="losing__img"
                    />
                    <p>
                        Oups, il semble que vous n'avez pas réussi à deviner le
                        mot...
                    </p>
                    <Link className="second__btn" to={"/gamesPage"}>
                        Retourner aux jeux →
                    </Link>
                </div>
            </div>
        );
    };

    const returnModal = () => {
        return (
            <div className="returnModal">
                <div className="content">
                    <h2 className="content__title">Vous avez gagné !</h2>
                    <img
                        src="/assets/images/logo.png"
                        alt="logo flamme avec un visage heureux"
                        className="losing__img"
                    />
                    {/* call API POST to add the collected points to the player */}
                    <p>🎉 Vous avez gagné x5 jetons 🎉</p>
                    <Link className="second__btn" to={"/games"}>
                        Retourner aux jeux →
                    </Link>
                </div>
            </div>
        );
    };

    const endGame = async () => {
        setFinish((current) => !current);
    };

    // Winning modal
    const winningModal = () => {
        return (
            <div className="winningModal">
                <div className="winningModal__content">
                    <img
                        src="/assets/photos/koumba-larroque.png"
                        alt="Athlète Koumba Larroque en action"
                        className="winning__img"
                    />
                    <div className="content__details">
                        <h2 className="winning__title">{answer.name}</h2>
                        <p className="content__id">{answer.identity}</p>
                        <p className="content__citation">{answer.citation}</p>
                    </div>
                    <p className="content__desc"> {answer.description} </p>
                    <div className="socials">
                        {answer.instagram && (
                            <a
                                href={answer.instagram}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src="/assets/icons/instagram.png"
                                    alt="Lien vers le compte Instagram"
                                    className="winning__instaLink"
                                />
                            </a>
                        )}
                        {answer.facebook && (
                            <a
                                href={answer.facebook}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src="/assets/icons/facebook.png"
                                    alt="Lien vers le compte Facebook"
                                    className="winning__fbLink"
                                />
                            </a>
                        )}
                    </div>
                    <span
                        onClick={() => {
                            endGame();
                        }}
                    >
                        Continuer →
                    </span>
                </div>
            </div>
        );
    };
    //#endregion

    const game = () => {
        return (
            <>
                {lost && losingModal()}
                {win && winningModal()}
                {finish && returnModal()}
                <div className="gameContainer">
                    <div className="content">
                        <div className="hangmanHeader">
                            <h2 className="content__title">Image camouflée</h2>
                            <ScratchCardComponent
                                image={selectedWord}
                                style={{left: 0}}
                            />
                            {/* <p className="hint">
                                Catégorie : athlète - arts martiaux
                            </p> */}
                            <div className="hiddenPicture__formItem">
                                <input
                                    className="hiddenPicture__input"
                                    type="text"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return <>{isPlaying === false ? rules() : game()}</>;
}

export default HiddenPicture;
